import { useEffect } from 'react';

import { metaMaskConnector } from '@/connectors/metaMask';
import { WALLET_CONNECT_STATUS } from '@/constants/wallets';
import { useAppDispatch } from '@/hooks';
import {
  useWalletAccount,
  useWalletConnector,
  useWalletIsActive,
  useWalletIsWrongNetwork,
} from '@/hooks/web3/useWalletNetwork';

import { useWalletConnectStatus } from '../hook';
import { setWalletConnectStatus } from '../walletSlice';

export const useListenWalletChange = (): void => {
  const dispatch = useAppDispatch();
  const isWrongNetwork = useWalletIsWrongNetwork();
  const account = useWalletAccount();
  const isActive = useWalletIsActive();
  const walletConnect = useWalletConnector();
  const walletStatus = useWalletConnectStatus();
  // watch wallet connect status
  useEffect(() => {
    if (isActive && !isWrongNetwork) {
      dispatch(setWalletConnectStatus({ status: WALLET_CONNECT_STATUS.CONNECTED }));
    } else if (!isActive && !account) {
      dispatch(setWalletConnectStatus({ status: WALLET_CONNECT_STATUS.UN_CONNECT }));
    } else if (isWrongNetwork) {
      dispatch(setWalletConnectStatus({ status: WALLET_CONNECT_STATUS.WRONG_NETWORK }));
    }
  }, [account, isActive, dispatch, isWrongNetwork]);

  useEffect(() => {
    // fix metamask lost connect bug by reconnect
    if (!isActive && walletStatus === WALLET_CONNECT_STATUS.UN_CONNECT) {
      // re-activate to connect metamask
      if (walletConnect === metaMaskConnector) {
        void metaMaskConnector.connectEagerly().catch(() => {
          console.warn('Failed to connect eagerly to metamask');
        });
      }
    }
  }, [isActive, walletConnect, walletStatus]);
};

export default function WalletGlobalEffect(): null {
  useListenWalletChange();
  return null;
}
