/**
 * @description Component-AddressInfo
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/ToolTip';
import { CHAIN_ID, NETWORK_ICONS, NETWORK_LABEL } from '@/constants/chain';
import { shortenAddress } from '@/utils/address';
interface IPropTypes {
  className?: string;
  chainId: CHAIN_ID | undefined;
  address: string;
  accountName?: string;
}
const AddressInfo: FC<IPropTypes> = function ({ chainId, address, accountName }) {
  const { t } = useTranslation();
  return (
    <div className="dapp-address-info">
      <dl>
        <dt>{t('common.recSelector.walletName')}</dt>
        <dd>{accountName || t('common.recSelector.wallet')}</dd>
      </dl>
      <dl>
        <dt>{t('common.recSelector.blockchain')}</dt>
        <dd>
          <img width={16} height={16} src={NETWORK_ICONS[chainId || CHAIN_ID.ARBITRUM]} />{' '}
          {NETWORK_LABEL[chainId || CHAIN_ID.ARBITRUM]}
        </dd>
      </dl>

      <dl>
        <dt>{t('common.recSelector.walletAddr')}</dt>
        <dd>
          {' '}
          <Tooltip title={address}>{shortenAddress(address)}</Tooltip>
        </dd>
      </dl>
    </div>
  );
};

export default AddressInfo;
