/**
 * @description Component-Login
 */
import './index.less';

import { Form, Row } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import bgSrc from '@/assets/images/bg_login.png';
import { ReactComponent as IconClose } from '@/assets/svg/icon_close_24.svg';
import { ReactComponent as ErrorIcon } from '@/assets/svg/icon_error.svg';
import { ReactComponent as IconEye } from '@/assets/svg/icon_login_eye1.svg';
import { ReactComponent as IconEyeClose } from '@/assets/svg/icon_login_eye2.svg';
import { ReactComponent as IconPw } from '@/assets/svg/icon_login_pw.svg';
import { ReactComponent as IconPwSolid } from '@/assets/svg/icon_login_pw_solid.svg';
import { ReactComponent as IconUser } from '@/assets/svg/icon_login_user.svg';
import { ReactComponent as IconUserSolid } from '@/assets/svg/icon_login_user_solid.svg';
import { ReactComponent as LogoColor } from '@/assets/svg/logo_color_dark.svg';
import { ReactComponent as LogoSymbol } from '@/assets/svg/logo_symbol.svg';
import { Button } from '@/components/Button';
import Input, { Password } from '@/components/Input';
import Modal from '@/components/Modal';
import { Tooltip } from '@/components/ToolTip';
import { FETCHING_STATUS } from '@/constants';
import { login } from '@/features/user/actions';
import { useLoginStatus } from '@/features/user/hooks';
import { useAppDispatch } from '@/hooks';
interface IPropTypes {
  className?: string;
  open: boolean;
  onClose: () => void;
}
const Login: FC<IPropTypes> = function ({ open, onClose }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showPass, setShowPass] = useState(false);
  const loginStatus = useLoginStatus();
  const navigate = useNavigate();
  const onFinish = useCallback(
    async (formValue: { username: string; password: string }) => {
      const res = await dispatch(login(formValue)).unwrap();
      if (res) {
        navigate('/home');
      }
    },
    [dispatch, navigate],
  );
  return (
    <Modal
      open={open}
      title={false}
      footer={false}
      closable={false}
      closeIcon={false}
      width={window.innerWidth}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      className="dapp-login full">
      <>
        <div className="dapp-login-bg">
          <img src={bgSrc} loading="lazy" />
        </div>
        <div className="dapp-login-header">
          <LogoColor />
          <Button onClick={onClose} type="text" icon={<IconClose />} />
        </div>
        <Row justify="center" className="dapp-login-content">
          <LogoSymbol />
          <div className="dapp-login-content-title">{t('common.login.title')}</div>
          <Form className="dapp-login-content-form" onFinish={onFinish}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div className="dapp-login-content-error">
                      <ErrorIcon />
                      {t('common.login.userNameRequired')}
                    </div>
                  ),
                },
              ]}
              name="username">
              <Input
                prefix={
                  <>
                    <IconUserSolid className="solid" /> <IconUser className="plain" />
                  </>
                }
                placeholder={t('common.login.namePlaceHolder')}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: (
                    <div className="dapp-login-content-error">
                      <ErrorIcon />
                      {t('common.login.passwordRequired')}
                    </div>
                  ),
                },
              ]}
              name="password">
              <Password
                type={showPass ? 'text' : 'password'}
                prefix={
                  <>
                    {' '}
                    <IconPw className="plain" />
                    <IconPwSolid className="solid" />
                  </>
                }
                className="dapp-input"
                visibilityToggle={{ visible: showPass, onVisibleChange: setShowPass }}
                suffix={
                  showPass ? (
                    <IconEyeClose onClick={() => setShowPass(!showPass)} />
                  ) : (
                    <IconEye onClick={() => setShowPass(!showPass)} />
                  )
                }
                placeholder={t('common.login.passPlaceHolder')}
              />
            </Form.Item>
            <Button loading={loginStatus === FETCHING_STATUS.FETCHING} type="primary" htmlType="submit">
              {loginStatus === FETCHING_STATUS.FETCHING ? t('common.login.btnTxt2') : t('common.login.btnTxt')}
            </Button>
          </Form>
          <Row justify="space-between" align="middle" className="dapp-login-content-footer">
            <Tooltip title={t('common.comingSoon')}>
              <Button type="link">{t('common.login.loginTrouble')}</Button>
            </Tooltip>
            <div className="dapp-login-content-footer-right">
              {t('common.login.new')}
              <Tooltip title={t('common.comingSoon')}>
                <Button type="link">{t('common.login.signUp')}</Button>
              </Tooltip>
            </div>
          </Row>
        </Row>
      </>
    </Modal>
  );
};

export default Login;
