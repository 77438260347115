import { ConfigProvider } from 'antd';
import React from 'react';

export default function AntDConfigProvider({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4d6aff',
          fontFamily: `Gilroy, 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
          colorError: '#d65c85',
        },
      }}>
      {children}
    </ConfigProvider>
  );
}
