import { CHAIN_ID, TokenInfo as Token } from '@derivation-tech/web3-core';
import { BigNumber, constants } from 'ethers';

import { chainConfigs } from '@/constants/chain';
import { TokenInfo } from '@/types/token';

export function getTokenInfo(token: Token, chainId: CHAIN_ID): TokenInfo {
  return {
    ...token,
    id: token.address.toLowerCase(),
    address: token.address.toLowerCase(),
    chainId,
  };
}

export function isWrappedNativeToken(chainId: CHAIN_ID, address: string, wrappedNativeToken?: Token): boolean {
  if (!wrappedNativeToken) {
    const chainConfig = chainConfigs[chainId];
    wrappedNativeToken = chainConfig.wrappedNativeToken;
  }

  return address?.toLowerCase() === wrappedNativeToken?.address?.toLowerCase();
}

export function isNativeTokenAddr(tokenAddr: string): boolean {
  if (!tokenAddr) return false;
  return tokenAddr.toLowerCase() === constants.AddressZero;
}

/**
 * get nativeToken if token is wrapped native token
 * or just return the token
 * @param wrappedNativeToken
 * @param chainId
 * @returns
 */
export function unWrapNativeToken(wrappedNativeToken: TokenInfo, chainId: CHAIN_ID): TokenInfo {
  const chainConfig = chainConfigs[chainId];
  if (chainConfig && isWrappedNativeToken(chainId, wrappedNativeToken.address)) {
    return chainConfig.nativeToken;
  }
  return wrappedNativeToken;
}

export function fixBalanceNumberDecimalsTo18(num: BigNumber, tokenDecimals: number): BigNumber {
  return num.mul(10 ** (18 - tokenDecimals));
}

export function adjustBalanceNumberToTokenDecimals(num: BigNumber, tokenDecimals: number): BigNumber {
  return num.div(10 ** (18 - tokenDecimals));
}
