export const DEFAULT_EMPTY_ACCOUNT = '';

export const DEFAULT_DECIMALS = 2;
export const INPUT_NUMBER_STEP = 0.01;
export const BANK_INFO = {
  bankName: 'HONG KONG DIGITAL BANK',
  bankNameShort: 'HKDB',
  accountName: 'DIGITAL HONG KONG DOLLAR',
  accountNumber: '099-012-56781234',
};
export const ADDRESS_INFO = {
  addressName: 'Address 1',
  address: '0x62a89D2a9339c88027116f32f6f7972C0CE36167',
};

export enum SEND_PAGES {
  BANK = 'BANK',
  WALLET = 'WALLET',
}
