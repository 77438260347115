/**
 * @description Component-Copied
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconAddrCopy } from '@/assets/svg/icon_copy_16.svg';
import useCopyClipboard from '@/hooks/useCopyClipboard';
import { isAddress } from '@/utils/address';

import { Tooltip } from '../ToolTip';

interface IPropTypes {
  title?: string;
  copiedTitle?: string;
  copyValue: string;
  showTitle?: boolean;
  checkAddress?: boolean;
  showTooltip?: boolean;
  onClicked?: () => void;
}
const Copied: FC<IPropTypes> = function ({
  title,
  copiedTitle,
  copyValue,
  showTitle,
  checkAddress = true,
  showTooltip = true,
  onClicked,
}) {
  const { t } = useTranslation();
  const [copied, setCopied] = useCopyClipboard(2000);
  title = title || t('common.copy');
  copiedTitle = copiedTitle || t('common.copied');
  return (
    <div className="dapp-copied">
      <Tooltip title={showTooltip ? (copied ? copiedTitle : title) : ''}>
        <a
          className="dapp-copied-btn"
          onClick={() => {
            setCopied(checkAddress ? isAddress(copyValue || '') || '' : copyValue);
            onClicked && onClicked();
          }}>
          <IconAddrCopy />
          {showTitle ? (copied ? copiedTitle : title) : ''}
        </a>
      </Tooltip>
    </div>
  );
};

export default Copied;
