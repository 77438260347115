/**
 * @description Component-UserDropDown
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDown } from '@/assets/svg/icon_chevron_down.svg';
import { Button } from '@/components/Button';
import { Tooltip } from '@/components/ToolTip';

import { ReactComponent as UserIcon } from './assets/user.svg';
interface IPropTypes {
  className?: string;
}
const UserDropDown: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('common.comingSoon')}>
      <Button className="dapp-user-drop-down">
        <UserIcon /> <ChevronDown />
      </Button>
    </Tooltip>
  );
};

export default UserDropDown;
