import { isMobile } from 'react-device-detect';
import ReactGA, { EventArgs } from 'react-ga';

export function catchException(error: Error | unknown): void {
  console.log('sending error ', error);
}

export enum GaCategory {
  HOME = 'HOME',
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
}

export function gaEvent({ category, action, label, value, nonInteraction }: EventArgs): void {
  ReactGA.event({
    category,
    action,
    value,
    label,
    nonInteraction,
  });
}

export function initReactGA(): void {
  const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  if (typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID && GOOGLE_ANALYTICS_ID !== 'test') {
    const testMode = process.env.NODE_ENV === 'test';
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, { testMode: testMode });
    ReactGA.set({
      customBrowserType: !isMobile
        ? 'desktop'
        : 'web3' in window || 'ethereum' in window
        ? 'mobileWeb3'
        : 'mobileRegular',
    });
  } else {
    ReactGA.initialize('test', { testMode: true, debug: true });
  }
}

export function setUserId(userId: string): void {
  ReactGA.set({ userId: userId });
}
