import { FiatToken } from '@derivation-tech/dhkd-sdk';
import { CHAIN_ID } from '@derivation-tech/web3-core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITokenBalanceInfoMap } from '@/types/balance';
import { formatEther } from '@/utils/numberUtil';

import { AppState } from '../store';

// import { setTradingRelatedPairsAndMap } from '@/state/pairs/actions';

export const getTokenBalance = createAsyncThunk(
  'balance/getTokenBalance',
  async (
    {
      userAddress,
      contract,
    }: {
      chainId: CHAIN_ID;
      userAddress: string;
      contract: FiatToken;
    },
    { getState },
  ): Promise<ITokenBalanceInfoMap | undefined> => {
    const {} = getState() as AppState;
    try {
      const balance = await contract.balanceOf(userAddress);
      const symbol = await contract.symbol();
      return { [symbol]: Number(formatEther(balance)) };
    } catch (e) {
      return undefined;
    }
  },
);
