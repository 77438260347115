import { DHKDSdk, FiatToken } from '@derivation-tech/dhkd-sdk';
import { CHAIN_ID } from '@derivation-tech/web3-core';

import { TOKENS } from '@/constants/token';
import { useAppSelector } from '@/hooks';

import { selectContract, selectCurrentToken, selectSDKContextState } from './web3Slice';

export function useSDKContext(chainId: CHAIN_ID | undefined): DHKDSdk | undefined {
  const sdkContext = useAppSelector(selectSDKContextState(chainId));
  return sdkContext;
}
export function useContract(token: TOKENS | undefined): FiatToken | undefined {
  const contract = useAppSelector(selectContract(token));
  return contract;
}
export function useCurrentToken(): TOKENS {
  const token = useAppSelector(selectCurrentToken);
  return token;
}
