/**
 * @description Component-AddTokenCard
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_round.svg';
import { Tooltip } from '@/components/ToolTip';
interface IPropTypes {
  className?: string;
}
const AddTokenCard: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('common.comingSoon')}>
      <div className="dapp-add-token-card">
        <AddIcon className="dapp-add-token-card-icon" />
        <div className="dapp-add-token-card-amount">{t('common.home.account.addTitle')}</div>
        <div className="dapp-add-token-card-symbol">{t('common.home.account.addSubtitle')}</div>
      </div>
    </Tooltip>
  );
};

export default AddTokenCard;
