import { initializeConnector } from '@web3-react/core';
import { Network } from '@web3-react/network';

import { CHAIN_WEBSOCKET_PROVIDERS, CONNECTOR_RPC_URLS } from '@/constants/rpc';
import { getDefaultChainId } from '@/utils/chain';

import { WebSocketConnector } from './WebSocketConnector';

const defaultChainId = getDefaultChainId();

export const [appJsonRpcConnector, appJsonRpcConnectorHooks] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: CONNECTOR_RPC_URLS, defaultChainId: defaultChainId }),
);

export const [websocketConnector, websocketConnectorHooks] = initializeConnector<WebSocketConnector>(
  (actions) => new WebSocketConnector({ actions, urlMap: CHAIN_WEBSOCKET_PROVIDERS, defaultChainId: defaultChainId }),
);

export const appConnector = websocketConnector;
export const appConnectorHooks = websocketConnectorHooks;
