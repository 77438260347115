import { DHKDSdk } from '@derivation-tech/dhkd-sdk';
import { CHAIN_ID, ZERO_ADDRESS } from '@derivation-tech/web3-core';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import { ITxEventTransformed } from '@/types/tx';
import { transformGraphEvent } from '@/utils/tx';

// import { AppState } from '../store';

export const addTransaction = createAction<ITxEventTransformed>('graph/addTransaction');
export const resetHistory = createAction<{ chainId: CHAIN_ID }>('graph/resetHistory');

export const getTxHistory = createAsyncThunk(
  'graph/getTxHistory',
  async ({
    userAddr,
    sdkContext,
    chainId,
  }: {
    chainId: CHAIN_ID;
    userAddr: string;
    sdkContext: DHKDSdk;
  }): Promise<ITxEventTransformed[] | undefined> => {
    if (sdkContext) {
      const history = await sdkContext.subgraph.getTransactionHistory(userAddr);
      const filteredHistory = _.filter(history, (h) => {
        const from = _.get(h.args, ['from']);
        const to = _.get(h.args, ['to']);
        const minter = _.get(h.args, ['minter']);
        const account = _.get(h.args, ['account']);
        return from !== ZERO_ADDRESS && to !== ZERO_ADDRESS && minter !== ZERO_ADDRESS && account !== ZERO_ADDRESS;
      });
      return _.map(filteredHistory, (h) => transformGraphEvent(userAddr, h, chainId));
    }
    return undefined;
  },
);
