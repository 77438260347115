/**
 * @description Component-BankRecipientSelector
 */
import './index.less';

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReviewIcon } from '@/assets/svg/icon_review_12.svg';
import { BANK_INFO } from '@/constants/global';
import { IBankInfo } from '@/types/user';

import BankInfo from '../BankInfo';
import { Option, Select } from '../Select';

interface IPropTypes {
  className?: string;
  onChange: (bank: IBankInfo) => void;
}
const BankRecipientSelector: FC<IPropTypes> = function ({ onChange }) {
  const { t } = useTranslation();
  useEffect(() => {
    onChange(BANK_INFO);
  }, []);
  return (
    <>
      <div className="dapp-bank-recipient-selector">
        <div className="dapp-bank-recipient-selector-title">{t('common.recSelector.title')}</div>
        <div className="dapp-bank-recipient-selector-select">
          <div className="dapp-bank-recipient-selector-select-label">{t('common.recSelector.label')}</div>
          <Select defaultValue={BANK_INFO.accountNumber}>
            <Option value={BANK_INFO.accountNumber}>
              <div className="select-selected">HKDB / ****1234</div>
              <div className="select-left">{BANK_INFO.bankNameShort}</div>
              <div className="select-right">
                <div className="select-right-top">{BANK_INFO.accountName}</div>
                <div className="select-right-bottom">{BANK_INFO.accountNumber}</div>
              </div>
            </Option>
          </Select>
        </div>
      </div>
      <div className="dapp-bank-recipient-detail">
        <div className="dapp-bank-recipient-detail-title">
          <ReviewIcon />
          {t('common.recSelector.detailTitle')}
        </div>
        <BankInfo />
      </div>
    </>
  );
};

export default BankRecipientSelector;
