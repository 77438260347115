import { CHAIN_ID, ChainContext, ChainInfo } from '@derivation-tech/web3-core';

import { IDappConfigInfo } from '@/types/chain';

import { getTokenInfo } from './token';

export function getChainConfigInfo(chainId: CHAIN_ID, chainInfo?: ChainInfo): IDappConfigInfo {
  if (!chainInfo) chainInfo = ChainContext.getChainInfo(chainId);
  const wrappedNativeToken = getTokenInfo(chainInfo.wrappedNativeToken, chainId);
  const nativeToken = getTokenInfo(chainInfo.nativeToken, chainId);

  const newConfig = {
    ...chainInfo,
    nativeToken,
    wrappedNativeToken: wrappedNativeToken,
  } as IDappConfigInfo;

  return newConfig;
}
