/**
 * @description Component-BankInfo
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BANK_INFO } from '@/constants/global';
interface IPropTypes {
  className?: string;
}
const BankInfo: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  return (
    <div className="dapp-bank-info">
      <dl>
        <dt>{t('common.recSelector.accountName')}</dt>
        <dd>{BANK_INFO.accountName}</dd>
      </dl>
      <dl>
        <dt>{t('common.recSelector.bank')}</dt>
        <dd>{BANK_INFO.bankName}</dd>
      </dl>

      <dl>
        <dt>{t('common.recSelector.bankNo')}</dt>
        <dd>{BANK_INFO.accountNumber}</dd>
      </dl>
    </div>
  );
};

export default BankInfo;
