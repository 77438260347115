import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import common from '@/locales/en/common.json';
import header from '@/locales/en/header.json';
import modal from '@/locales/en/modal.json';

const resources = {
  en: {
    translation: {
      common: common,
      header,
      modal,
    },
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    // backend: {
    //   loadPath: `@/locales/{{lng}}/*.json`,
    // },
    react: {
      useSuspense: true,
    },
    fallbackLng: 'en',
    preload: ['en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
