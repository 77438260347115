import './i18n';
import './initSettings';
import 'react-toastify/dist/ReactToastify.css';
import './themes/index.less';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import ProviderWrapper from './ProviderWrapper';

export default function main(App: () => JSX.Element): void {
  const container = document.getElementById('root');

  if (container) {
    const root = createRoot(container);
    root.render(
      <div>
        <Suspense fallback="">
          <ProviderWrapper>
            <App />
          </ProviderWrapper>
        </Suspense>
      </div>,
    );
  }
}
