import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUS } from '@/constants';

import { AppState } from '../store';

export interface ITransactionState {
  userTxStatus: FETCHING_STATUS;
}

export const initialState: ITransactionState = {
  userTxStatus: FETCHING_STATUS.INIT,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    console.log('🚀 ~ file: transactionSlice.ts:20 ~ builder:', builder);
  },
});

export const selectTransactionState = (state: AppState): ITransactionState => state.transaction;

export default transactionSlice.reducer;
