/**
 * @description Component-Select
 */
import './index.less';

import { Select as AntSelect, SelectProps } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as IconDown } from '@/assets/svg/icon_chevron_down.svg';

// import './select.less';

export function Select<T>({ children, className, popupClassName, ...props }: SelectProps<T>): JSX.Element {
  return (
    <AntSelect<T>
      {...props}
      className={classNames('dapp-select', className)}
      suffixIcon={<IconDown />}
      popupClassName={classNames('dapp-select-dropdown', popupClassName)}>
      {children}
    </AntSelect>
  );
}

export const { Option } = AntSelect;
