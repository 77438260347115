/**
 * @description Component-Home
 */
import './index.less';

import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SideMenu from './SideMenu';
interface IPropTypes {
  className?: string;
}
const Home: FC<IPropTypes> = function () {
  return (
    <div className="dapp-home">
      <SideMenu />
      <div className="dapp-home-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
