import { TOKENS } from '@/constants/token';

export enum TxSide {
  SEND = 'Send',
  DEPOSIT = 'Deposit',
}
export enum TxTarget {
  BANK = 'BANK',
  WALLET = 'Wallet',
}
export enum TxType {
  MINT = 'Mint',
  TRANSFER = 'Transfer',
  BURN = 'Burn',
}
export interface ITxEventTransformed {
  side: TxSide;
  chainId: number;
  timestamp: number;
  amount: string;
  target: TxTarget;
  from?: string;
  txHash: string;
  logIndex: number;
  to?: string;
  minter?: string;
  account?: string;
  symbol: TOKENS;
}
