import { CHAIN_ID } from '@derivation-tech/web3-core';

import ArbIcon from '@/assets/svg/icon_net_arbitrum.svg';
import EtheIcon from '@/assets/svg/icon_net_ethereum.svg';
import PolyIcon from '@/assets/svg/icon_net_polygon.svg';
import { IDappConfigInfo } from '@/types/chain';
import { getChainConfigInfo } from '@/utils/chainConfig';

/**
 * available chain id array
 */
let SUPPORTED_CHAIN_ID = [CHAIN_ID.GOERLI, CHAIN_ID.ARBITRUM, CHAIN_ID.POLYGON, CHAIN_ID.ETHEREUM];

if (process.env.REACT_APP_AVAILABLE_CHAIN_ID) {
  const arr = process.env.REACT_APP_AVAILABLE_CHAIN_ID.split(',');
  if (arr && arr.length > 0) {
    SUPPORTED_CHAIN_ID = arr.map((chainIdStr) => Number(chainIdStr));
  }
}

/**
 * display chain id array in [Select a Network] modal
 * Usually remove the test network
 */
let DISPLAYABLE_CHAIN_ID = [CHAIN_ID.GOERLI];

if (process.env.REACT_APP_DISPLAYABLE_CHAIN_ID) {
  const arr = process.env.REACT_APP_DISPLAYABLE_CHAIN_ID.split(',');
  if (arr && arr.length > 0) {
    DISPLAYABLE_CHAIN_ID = arr.map((chainIdStr) => Number(chainIdStr));
  }
}

/**
 * default chain id for wrong network
 */
const DEFAULT_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');

const chainConfigs: {
  [chainId: number]: IDappConfigInfo;
} = {
  [CHAIN_ID.GOERLI]: getChainConfigInfo(CHAIN_ID.GOERLI),
  [CHAIN_ID.ARBITRUM]: getChainConfigInfo(CHAIN_ID.ARBITRUM),
  [CHAIN_ID.POLYGON]: getChainConfigInfo(CHAIN_ID.POLYGON),
  [CHAIN_ID.ETHEREUM]: getChainConfigInfo(CHAIN_ID.ETHEREUM),
};

const CHAIN_SHORT_NAME_MAP = {
  [CHAIN_ID.ARBITRUM]: 'Arbitrum',
};

export {
  CHAIN_ID,
  CHAIN_SHORT_NAME_MAP,
  chainConfigs,
  DEFAULT_CHAIN_ID,
  DISPLAYABLE_CHAIN_ID,
  NETWORK_LABEL,
  SUPPORTED_CHAIN_ID,
};

export enum CHAIN_AVAILABLE {
  ARBITRUM = 'Arbitrum',
  POLYGON = 'Polygon',
  ETHEREUM = 'Ethereum',
}

const NETWORK_LABEL: { [chainId in CHAIN_ID]?: string } = {
  [CHAIN_ID.ETHEREUM]: 'Ethereum',
  [CHAIN_ID.POLYGON]: 'Polygon',
  [CHAIN_ID.ARBITRUM]: 'Arbitrum',
  [CHAIN_ID.GOERLI]: 'Goerli',
};

export const NETWORK_ICONS: { [chainId in CHAIN_ID]?: string } = {
  [CHAIN_ID.ETHEREUM]: EtheIcon,
  [CHAIN_ID.POLYGON]: PolyIcon,
  [CHAIN_ID.ARBITRUM]: ArbIcon,
  [CHAIN_ID.GOERLI]: ArbIcon,
};
