import './index.less';

import { SpinProps } from 'antd';
import React from 'react';

const Loading: React.FC<SpinProps> = (props) => {
  if (!props?.spinning) {
    return <>{props.children}</>;
  }
  return (
    <div className="dapp-loading">
      <div className="dapp-loading-part">
        <div>
          <span className="one h6"></span>
          <span className="two h3"></span>
        </div>
      </div>

      <div className="dapp-loading-part">
        <div>
          <span className="one h1"></span>
          <span className="two h4"></span>
        </div>
      </div>

      <div className="dapp-loading-part">
        <div>
          <span className="one h5"></span>
          <span className="two h2"></span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
