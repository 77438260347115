import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';

import { FETCHING_STATUS } from '@/constants';
import { getDefaultChainId } from '@/utils/chain';

import { AppState } from '../store';
import { changeCurrentChainId } from './actions';

export interface IChainState {
  currentChainId: number;
  prevChainId: number;

  chainBlockStatus: {
    [chainId: number]: {
      blockNumber: number;
      // blockStatus?: IIndexerBlockStatus;
      fetchingStatus?: FETCHING_STATUS;
      error?: SerializedError;
    };
  };
}

const defaultChainId = getDefaultChainId();

const initialState: IChainState = {
  currentChainId: defaultChainId,
  prevChainId: 0,

  chainBlockStatus: {},
};

export const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    changePrevChainId: (state, { payload: { chainId } }: PayloadAction<{ chainId: number }>) => {
      state.prevChainId = chainId || state.currentChainId;
    },
    updateBlockNumber: (
      state,
      { payload: { chainId, blockNumber } }: PayloadAction<{ chainId: number; blockNumber: number }>,
    ) => {
      _.set(state.chainBlockStatus, [chainId, 'blockNumber'], blockNumber);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeCurrentChainId.fulfilled, (state, action) => {
      const { arg } = action.meta;
      // the prevChain ID must be set before the currentChainId
      if (state.prevChainId === 0) {
        state.prevChainId = arg.chainId;
        // when the actions carry the same chainId consecutively
      }
      state.currentChainId = arg.chainId;
    });
  },
});

export const { changePrevChainId, updateBlockNumber } = chainSlice.actions;

export const selectChainState = (state: AppState): IChainState => state.chain;
export const selectCurrentId = (state: AppState): number => state.chain.currentChainId;
export const selectChainBlockStatus = (
  state: AppState,
): {
  [chainId: number]: {
    blockNumber: number;
    fetchingStatus?: FETCHING_STATUS;
    error?: SerializedError;
  };
} => state.chain.chainBlockStatus;

export default chainSlice.reducer;
