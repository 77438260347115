import { DhkDTransactionEvent } from '@derivation-tech/dhkd-sdk';
import { CHAIN_ID } from '@derivation-tech/web3-core';
import _ from 'lodash';

import { TOKENS } from '@/constants/token';
import { ITxEventTransformed, TxSide, TxTarget, TxType } from '@/types/tx';

import { isAddress } from './address';
import { formatEther } from './numberUtil';

export function transformGraphEvent(
  userAddr: string,
  tx: DhkDTransactionEvent,
  chainId: CHAIN_ID,
): ITxEventTransformed {
  let txSide = TxSide.DEPOSIT;

  let target = TxTarget.BANK;
  if (tx.name === TxType.BURN) {
    txSide = TxSide.SEND;
  }
  if (tx.name === TxType.TRANSFER) {
    const to = _.get(tx.args, ['to']);
    if (to !== userAddr.toLocaleLowerCase()) {
      txSide = TxSide.SEND;
    }
    if (isAddress(to)) {
      target = TxTarget.WALLET;
    }
  }
  const amount = formatEther(_.get(tx.args, ['amount']) || _.get(tx.args, ['value']) || '0');
  return {
    ...tx,
    side: txSide,
    target,
    amount,
    timestamp: tx.timestamp * 1000,
    chainId,
    txHash: tx.txHash,
    logIndex: tx.logIndex,
    from: _.get(tx.args, ['from']),
    to: _.get(tx.args, ['to']),
    symbol: tx.tokenInfo.symbol as TOKENS,
  };
}
