/**
 * @description Component-ChainSelector
 */
import './index.less';

import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArbIcon } from '@/assets/svg/icon_net_arbitrum.svg';
import { ReactComponent as EtheIcon } from '@/assets/svg/icon_net_ethereum.svg';
import { ReactComponent as PolyIcon } from '@/assets/svg/icon_net_polygon.svg';
import { Tooltip } from '@/components/ToolTip';
import { CHAIN_ID } from '@/constants/chain';
import { useChainId } from '@/hooks/web3/useChain';
import { useSwitchAppAndWalletNetwork } from '@/hooks/web3/useSwitchConnectorNetwork';
interface IPropTypes {
  className?: string;
  disabled?: boolean;
}
const ChainSelector: FC<IPropTypes> = function ({ disabled }) {
  const switchNetwork = useSwitchAppAndWalletNetwork();
  const chainChosen = useChainId();
  const { t } = useTranslation();
  return (
    <div className="dapp-chain-selector-chains">
      <div
        onClick={() => !disabled && switchNetwork(CHAIN_ID.ARBITRUM)}
        className={classNames('dapp-chain-selector-chain', chainChosen === CHAIN_ID.ARBITRUM && 'selected')}>
        <ArbIcon /> Arbitrum
      </div>
      <Tooltip title={t('common.comingSoon')}>
        <div className={classNames('dapp-chain-selector-chain', chainChosen === CHAIN_ID.POLYGON && 'selected')}>
          <PolyIcon /> Polygon
        </div>
      </Tooltip>
      <Tooltip title={t('common.comingSoon')}>
        <div className={classNames('dapp-chain-selector-chain', chainChosen === CHAIN_ID.ETHEREUM && 'selected')}>
          <EtheIcon /> Ethereum
        </div>
      </Tooltip>
    </div>
  );
};

export default ChainSelector;
