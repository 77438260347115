/**
 * @description Component-TokenDetail
 */
import './index.less';

import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as DepositIcon } from '@/assets/svg/icon_bal_receive.svg';
import { ReactComponent as SendIcon } from '@/assets/svg/icon_bal_send.svg';
import { ReactComponent as BackIcon } from '@/assets/svg/icon_chevron_left.svg';
import { Button } from '@/components/Button';
import LoadingWrap from '@/components/LoadingWrap';
import { FETCHING_STATUS } from '@/constants';
import { TOKENS, TOKENS_ICON_MAP } from '@/constants/token';
import { useTokenBalanceByChainIdAndToken, useTokenBalanceStatus } from '@/features/balance/hook';
import { useTokenTxHistory } from '@/features/graph/hooks';
import { setCurrentToken } from '@/features/web3/actions';
import { useContract } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import AddQuotesButton from '@/pages/components/AddQuotesButton';
import UserDropDown from '@/pages/components/UserDropDown';
import { formatNumber } from '@/utils/numberUtil';

import Transactions from '../Transactions';
import DepositFromBank from './DepositFromBank';
import DepositFromWallet from './DepositFromWallet';
import SendToBank from './SendToBank';
import SendToWallet from './SendToWallet';
interface IPropTypes {
  className?: string;
}
const TokenDetail: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const chainId = useChainId();
  const txs = useTokenTxHistory(chainId, token as TOKENS);
  const dispatch = useAppDispatch();
  const contract = useContract(token as TOKENS);
  const balance = useTokenBalanceByChainIdAndToken(token as TOKENS, chainId);
  const balanceStatus = useTokenBalanceStatus(token as TOKENS, chainId);
  useEffect(() => {
    token && dispatch(setCurrentToken(token as TOKENS));
  }, [dispatch, token]);
  return (
    <div className="dapp-token-detail">
      <div className="dapp-token-detail-header">
        <Button
          onClick={() => {
            if (history.state.length > 1) {
              history.go(-1);
            } else {
              navigate('/home');
            }
          }}
          type="text"
          icon={<BackIcon />}
          className="dapp-token-detail-header-back">
          {t('common.detail.back')}
        </Button>
        <UserDropDown />
      </div>
      <div className="dapp-token-detail-content">
        <div className="dapp-token-detail-content-top">
          <img width={24} height={24} src={TOKENS_ICON_MAP[token as TOKENS]} />
          <span> {token} </span>
          <span>{t('common.balance')}</span>
          {chainId && token && contract && (
            <AddQuotesButton
              tokenInfo={{
                chainId: chainId,
                symbol: token,
                address: contract.address,
                decimals: 18,
                id: contract.address,
              }}
            />
          )}
        </div>
        <div className="dapp-token-detail-content-bottom">
          <div className="dapp-token-detail-content-bottom-amount">
            {' '}
            <LoadingWrap loading={balanceStatus !== FETCHING_STATUS.DONE}>{formatNumber(balance, 2)} </LoadingWrap>
          </div>
          <div className="dapp-token-detail-content-bottom-right">
            <div className="dapp-token-detail-content-bottom-right-col">
              <div className="dapp-token-detail-content-bottom-right-col-label">
                <SendIcon /> {t('common.detail.sendTo')}
              </div>
              <div className="dapp-token-detail-content-bottom-right-col-btns">
                <SendToBank />
                <SendToWallet />
              </div>
            </div>
            <div className="dapp-token-detail-content-bottom-right-col">
              <div className="dapp-token-detail-content-bottom-right-col-label">
                <DepositIcon /> {t('common.detail.depFrom')}
              </div>
              <div className="dapp-token-detail-content-bottom-right-col-btns">
                <DepositFromBank />
                <DepositFromWallet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transactions txs={txs} />
    </div>
  );
};

export default TokenDetail;
