import './header.less';

import { Button, Layout, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from './Logo';

const { Header: AntHeader } = Layout;
export default function Header({ className, openLogin }: { className?: string; openLogin: () => void }): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames('header', className)}>
        <AntHeader className={`header-container `}>
          <div className="header-main">
            <Logo />
          </div>
          <div className="header-menu">
            <div className="header-menu-item">
              {t('common.landing.headerMenu.home')}
              <div className="line"></div>
            </div>
            <Tooltip title={t('common.comingSoon')}>
              <div className="header-menu-item">
                {t('common.landing.headerMenu.solutions')}
                <div className="line"></div>
              </div>
            </Tooltip>
            <Tooltip title={t('common.comingSoon')}>
              <div className="header-menu-item">
                {t('common.landing.headerMenu.resources')}
                <div className="line"></div>
              </div>
            </Tooltip>
            <Tooltip title={t('common.comingSoon')}>
              <div className="header-menu-item">
                {t('common.landing.headerMenu.company')}
                <div className="line"></div>
              </div>
            </Tooltip>
          </div>
          <div className="header-right">
            <Tooltip title={t('common.comingSoon')}>
              <Button type="link">{t('common.landing.headerMenu.signUp')}</Button>
            </Tooltip>
            <Button type="link" onClick={openLogin}>
              {t('common.landing.headerMenu.login')}
            </Button>
          </div>
        </AntHeader>
      </div>
    </>
  );
}
