/**
 * @description Component-Account
 */
import './index.less';

import _ from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TOKENS } from '@/constants/token';
import { useTxHistory } from '@/features/graph/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import UserDropDown from '@/pages/components/UserDropDown';

import Transactions from '../Transactions';
import AddTokenCard from './AddTokenCard';
import TokenCard from './TokenCard';
interface IPropTypes {
  className?: string;
}
const Account: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const chainId = useChainId();
  const txs = useTxHistory(chainId);
  return (
    <div className="dapp-account">
      <div className="dapp-account-title">
        {t('common.home.account.title')} <UserDropDown />
      </div>
      <div className="dapp-account-assets">
        {_.values(TOKENS).map((token) => (
          <TokenCard key={token} token={token} />
        ))}
        <AddTokenCard />
      </div>
      <Transactions txs={txs} />
    </div>
  );
};

export default Account;
