import { useMemo } from 'react';

import { FETCHING_STATUS } from '@/constants';
import { useAppSelector } from '@/hooks';
import { IUserWallet } from '@/types/user';

import {
  IUserState,
  selectCurrentUserAddr,
  selectLoginStatus,
  selectUserState,
  selectUserWalletInfo,
  selectUseTxStatus,
} from './userSlice';

export function useUserState(): IUserState {
  const userState = useAppSelector(selectUserState);
  return userState;
}

export function useUserAddrState(): string | undefined {
  // adapt cosplay mode
  const account = useAppSelector(selectCurrentUserAddr);
  return useMemo(() => {
    return account;
  }, [account]);
}

export function useUserWalletInfo(): IUserWallet | undefined {
  const wallet = useAppSelector(selectUserWalletInfo);
  return useMemo(() => wallet, [wallet]);
}
export function useLoginStatus(): FETCHING_STATUS {
  const loginStatus = useAppSelector(selectLoginStatus);
  return useMemo(() => loginStatus, [loginStatus]);
}
export function useUserTxStatus(): FETCHING_STATUS {
  const loginStatus = useAppSelector(selectUseTxStatus);
  return useMemo(() => loginStatus, [loginStatus]);
}
