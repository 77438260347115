import * as Comlink from 'comlink';

import { UserWorkerType } from './UserWorker';

export function getUserWorker(): Worker {
  return new Worker(new URL('@/worker/UserWorker.ts', import.meta.url));
}

export function registerWorkers(): void {
  window.userWorker = getUserWorker();
}

export function getWrappedUserWorker(): Comlink.Remote<UserWorkerType> {
  return Comlink.wrap<UserWorkerType>(getUserWorker());
}
