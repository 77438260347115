import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WALLET_CONNECT_STATUS, WalletType } from '@/constants/wallets';

import { AppState } from '../store';

export interface IWalletState {
  walletType: WalletType;
  walletConnectStatus: WALLET_CONNECT_STATUS;
}

export const initialState: IWalletState = {
  walletType: WalletType.METAMASK,
  walletConnectStatus: WALLET_CONNECT_STATUS.UN_CONNECT,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletType: (state, { payload: { type } }: PayloadAction<{ type: WalletType }>) => {
      state.walletType = type;
    },
    setWalletConnectStatus: (state, { payload: { status } }: PayloadAction<{ status: WALLET_CONNECT_STATUS }>) => {
      state.walletConnectStatus = status;
    },
  },
});

export const { setWalletType, setWalletConnectStatus } = walletSlice.actions;

export const selectWalletState = (state: AppState): IWalletState => state.wallet;
export const selectWalletType = (state: AppState): WalletType => state.wallet.walletType;
export const selectWalletConnectStatus = (state: AppState): WALLET_CONNECT_STATUS => state.wallet.walletConnectStatus;

export default walletSlice.reducer;
