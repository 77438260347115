import { notification } from 'antd';
import React, { Suspense, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader/root';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ErrorBoundary from '@/components/ErrorBoundary';
import Loading from '@/components/Loading';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import LayoutComponent from '@/layout';
import PageLayout from '@/layout/PageLayout';

import GlobalEffect from './GlobalEffect';
import Home from './Home';
import Account from './Home/Account';
import TokenDetail from './Home/TokenDetail';
import Landing from './Landing/index';

const defaultUrl = '/';
notification.config({
  bottom: 88,
});

function App(): JSX.Element {
  const { deviceType, isMobile } = useMediaQueryDevice();
  useEffect(() => {
    window.document.documentElement.className = deviceType;
  }, [deviceType]);
  const NavigateToDefaultPage = useMemo(() => {
    return <Navigate to={isMobile ? '' : defaultUrl} />;
  }, [isMobile]);

  useEffect(() => {
    // adapt mobile height
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
      doc.style.setProperty('--doc-width', `${window.innerWidth}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
  }, []);

  const location = useLocation();
  useEffect(() => {
    const layers = location.pathname.split('/').length;
    if (layers > 2) {
      document.body.classList.add('second-layer');
    } else {
      document.body.classList.remove('second-layer');
    }
  }, [location.pathname]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      navigate('', { replace: true });
    }
  }, [isMobile, navigate]);

  return (
    <div className={`App ${deviceType}`}>
      <LayoutComponent>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div style={{ marginTop: 24 }}>
                <Loading size="large" />
              </div>
            }>
            <GlobalEffect></GlobalEffect>
            <Routes>
              <Route>
                <Route path="/" element={<PageLayout />}>
                  <Route path="" element={<Landing />} />
                  <Route path="home" element={<Home />}>
                    <Route path="" element={<Account />} />
                    <Route path="token/:token" element={<TokenDetail />} />
                  </Route>
                  <Route path="" element={NavigateToDefaultPage}></Route>
                </Route>
                <Route path="*" element={NavigateToDefaultPage} />
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </LayoutComponent>
    </div>
  );
}

export default hot(App);
