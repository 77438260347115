import localforage from 'localforage';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CREATED_WALLET } from '@/constants/storage';
import { getTxHistory } from '@/features/graph/actions';
import { useSDKContext } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import { IUserWallet } from '@/types/user';

import { setUserWalletInfo } from '../actions';
import { UserEventListener } from '../EventListener';
import { useUserWalletInfo } from '../hooks';
export function useInitWalletInfo(): null {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getStorageInfo = useCallback(async () => {
    const storedInfo = await localforage.getItem(CREATED_WALLET);
    if (storedInfo) {
      console.log('🚀 ~ file: index.tsx:17 ~ getStorageInfo ~ storedInfo:', storedInfo);
      dispatch(setUserWalletInfo(storedInfo as IUserWallet));
    } else {
      navigate('/');
    }
  }, [dispatch, navigate]);
  useEffect(() => {
    getStorageInfo();
    // must be zero deps
  }, []);

  return null;
}
export function useFetchUserTxHistory(): null {
  const dispatch = useAppDispatch();
  const chainId = useChainId();
  const walletInfo = useUserWalletInfo();
  const sdkContext = useSDKContext(chainId);

  useEffect(() => {
    chainId &&
      walletInfo &&
      sdkContext &&
      dispatch(
        getTxHistory({
          chainId,
          userAddr: walletInfo?.address,
          sdkContext,
        }),
      );
    // must be zero deps
  }, [chainId, dispatch, sdkContext, walletInfo]);

  return null;
}

function UserGlobalEffect(): JSX.Element {
  useInitWalletInfo();
  useFetchUserTxHistory();
  return <UserEventListener />;
}

export default React.memo(UserGlobalEffect);
