import { ArgsProps, IconType } from 'antd/es/notification/interface';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types/index';

import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useEtherscanLink } from '@/hooks/web3/useChain';

interface INotificationArgs extends ArgsProps {
  type?: IconType;
  tx?: string;
  link?: string;
}

const cachedNotify: { [tx: string]: string } = {};

export const useTxNotification = (): {
  open: (args: INotificationArgs) => void;
  success: (args: INotificationArgs) => void;
  info: (args: INotificationArgs) => void;
  warning: (args: INotificationArgs) => void;
  error: (args: INotificationArgs) => void;
} => {
  const { isMobile } = useMediaQueryDevice();
  const getEtherscanLink = useEtherscanLink();
  /**
   * Notification wrapper, add tx open func
   * @param args Notification args
   */
  const openNotification = useCallback(
    (args: INotificationArgs): void => {
      let type = 'info';
      if (args.type) {
        type = args.type;
      }

      const toastDom = (
        <div className="dapp-notification-content">
          <div className="dapp-notification-content-title">{args.message}</div>
          {args.description && <div className="dapp-notification-content-desc">{args.description}</div>}
        </div>
      );
      const toastConfig: ToastOptions = {
        onClick: () => {
          if (args.link) {
            window.open(args.link);
          } else if (args.tx) {
            window.open(getEtherscanLink(args.tx, 'transaction'));
          }
        },
        type: type as TypeOptions,
        position: isMobile ? 'top-right' : 'bottom-right',
        autoClose: args.type === 'error' ? 30000 : 5000,
      };

      if (type === 'info') {
        const id = toast.loading(toastDom, toastConfig);
        args.tx && (cachedNotify[args.tx] = id.toString());
      } else if (args.tx && cachedNotify[args.tx]) {
        toast.update(cachedNotify[args.tx], {
          ...toastConfig,
          render: toastDom,
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
        });
      } else {
        toast(toastDom, toastConfig);
      }
    },
    [getEtherscanLink, isMobile],
  );

  /**
   *  add click
   */
  const txNotification = useMemo(() => {
    const res = {
      open: openNotification,
      success: openNotification,
      info: openNotification,
      warning: openNotification,
      error: openNotification,
    };

    const types: IconType[] = ['success', 'info', 'warning', 'error'];
    types.forEach((type: IconType) => {
      res[type] = (args: INotificationArgs): void =>
        res.open({
          ...args,
          type,
        });
    });
    return res;
  }, [openNotification]);

  return txNotification;
};
