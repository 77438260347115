import { AnyAction, Middleware, ThunkDispatch } from '@reduxjs/toolkit';

export const chainConfigMiddleware: Middleware<
  Record<string, unknown>,
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = () => (next) => async (action) => {
  // eslint-disable-next-line prefer-const
  let result = next(action);

  if (action.type.includes(`chain/changeCurrentChainId`)) {
    // console.log('dispatching', action);
    // console.log('next state', storeAPI.getState());
  }
  return result;
};
