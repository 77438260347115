import { CHAIN_ID } from '@derivation-tech/web3-core';
import { useMemo } from 'react';

import { FETCHING_STATUS } from '@/constants';
import { useAppSelector } from '@/hooks';
import { ITokenBalanceInfoMap } from '@/types/balance';

import { selectBalanceMapState, selectTokenBalance, selectTokenBalanceStatus } from './balanceSlice';

export function useTokenBalanceMapState(chainId: number | undefined): ITokenBalanceInfoMap | undefined {
  const chainBalanceMap = useAppSelector(selectBalanceMapState(chainId));

  return useMemo(() => chainBalanceMap, [chainBalanceMap]);
}

export function useTokenBalanceByChainIdAndToken(token: string | undefined, chainId: CHAIN_ID | undefined): number {
  const tokenBalance = useAppSelector(selectTokenBalance(chainId, token));
  return useMemo(() => tokenBalance, [tokenBalance]);
}
export function useTokenBalanceStatus(token: string | undefined, chainId: CHAIN_ID | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTokenBalanceStatus(chainId, token));
  return useMemo(() => status, [status]);
}
