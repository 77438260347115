import { useEffect } from 'react';

import { TOKENS } from '@/constants/token';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import { useAppProvider } from '@/hooks/web3/useWeb3Network';

import { initContracts, initSDKContext } from '../actions';
import { useSDKContext } from '../hook';

/**
 * watch provider change
 * @returns
 */
export function useProviderWatcher(): null {
  const provider = useAppProvider();
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (chainId && provider) {
      dispatch(initSDKContext({ chainId, provider }));
    }
  }, [chainId, dispatch, provider]);

  return null;
}
export function useInitContracts(): null {
  const provider = useAppProvider();
  const chainId = useChainId();
  const sdk = useSDKContext(chainId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (chainId && provider && sdk) {
      dispatch(initContracts({ chainId, provider, sdk, token: TOKENS.DHKD }));
      dispatch(initContracts({ chainId, provider, sdk, token: TOKENS.DUSD }));
    }
  }, [chainId, dispatch, provider, sdk]);

  return null;
}
export default function Web3GlobalEffect(): null {
  // useInitiateLeaderShip(chainId);
  useProviderWatcher();
  useInitContracts();
  return null;
}
