/**
 * @description Component-BalanceMax
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';
import { formatNumber } from '@/utils/numberUtil';
interface IPropTypes {
  className?: string;
  balance: number;
  onClick: () => void;
}
const BalanceMax: FC<IPropTypes> = function ({ balance, onClick }) {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} type="text" className="dapp-balance-max">
      {formatNumber(balance, 2)} <span className="highlight">{t('common.amountInput.max')}</span>
    </Button>
  );
};

export default BalanceMax;
