import { CHAIN_ID } from '@derivation-tech/web3-core';

export const CONNECTOR_RPC_URLS: { [chainId: number]: string[] } = {
  [CHAIN_ID.ARBITRUM]: [process.env.REACT_APP_ARBITRUM_RPC || ''],
  [CHAIN_ID.ETHEREUM]: [process.env.REACT_APP_ETHEREUM_RPC || ''],
  [CHAIN_ID.POLYGON]: [process.env.REACT_APP_POLYGON_RPC || ''],
  [CHAIN_ID.GOERLI]: [process.env.REACT_APP_GOERLI_RPC || ''],
};

export const CHAIN_WEBSOCKET_PROVIDERS: { [chainId: number]: string[] } = {
  [CHAIN_ID.POLYGON]: [process.env.REACT_APP_POLYGON_PROVIDER || ''],
  [CHAIN_ID.GOERLI]: [process.env.REACT_APP_GOERLI_PROVIDER || ''],
  [CHAIN_ID.ARBITRUM]: [process.env.REACT_APP_ARBITRUM_PROVIDER || ''],
  [CHAIN_ID.ETHEREUM]: [process.env.REACT_APP_ETHEREUM_PROVIDER || ''],
};
