import './index.less';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/ToolTip';
import { useWalletProvider } from '@/hooks/web3/useWalletNetwork';
import { TokenInfo } from '@/types/token';

import { ReactComponent as IconWallet } from './assets/icon_acct_metamask.svg';
interface IAddQuotesButtonProps {
  tokenInfo: TokenInfo;
}

export default function AddQuotesButton({ tokenInfo }: IAddQuotesButtonProps): JSX.Element {
  const walletProvider = useWalletProvider();
  const { t } = useTranslation();

  const onClickAddQuotes = useCallback(() => {
    if (walletProvider && walletProvider.provider.isMetaMask && walletProvider.provider.request) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {
        type: 'ERC20',
        options: {
          address: tokenInfo.address,
          symbol: tokenInfo.symbol,
          decimals: tokenInfo.decimals,
          image: tokenInfo.logoURI,
        },
      };
      walletProvider.provider
        .request({
          method: 'wallet_watchAsset',
          params,
        })
        .then((success) => {
          if (success) {
            console.record('wallet', `Successfully added [${tokenInfo.symbol}] to [MetaMask]`);
          } else {
            throw new Error('Something went wrong.');
          }
        })
        .catch(console.error);
    }
  }, [walletProvider, tokenInfo]);
  return (
    <Tooltip
      title={t('common.addToken', {
        symbol: tokenInfo.symbol,
      })}>
      <IconWallet className="btn-add-quote" onClick={onClickAddQuotes} />
    </Tooltip>
  );
}
