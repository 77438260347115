import BN from 'bignumber.js';
import { BigNumber, BigNumberish, utils } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

import { DEFAULT_DECIMAL_PLACES, ZERO } from '@/constants';

// the util function to make a bignumber, should be used whenever making a big number is need.
export function toBN(value: BigNumberish | BN, unit: 'normal' | 'ether' = 'normal'): BN {
  let number = value;
  if (unit === 'ether' && BigNumber.isBigNumber(value)) {
    number = utils.formatEther(value);
  }
  if (typeof number === 'undefined') {
    number = 0;
  }
  return new BN(number.toString(10), 10);
}

// equivalent to math.floor
export function bNRDown(value: BigNumberish | BN): BN {
  return toBN(value).integerValue(BN.ROUND_FLOOR);
}

// converting a bigNumber to string, should be used whenever converting is needed
export function bNToS(value: BigNumberish | BN, radix = 10): string {
  const res = toBN(value);
  return res.isNaN() ? '' : res.toString(radix);
}

/**
 * to show currency number with comma
 */
const toCurrencyNumber = (x: number | string): string => {
  const parts = x.toString().split('.');
  if (parts.length > 0) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return '';
};

const CURRENCY_UNITS: ('K' | 'M' | 'B' | 'T')[] = ['K', 'M', 'B', 'T'];
const CURRENCY_UNIT_NUM = 10 ** 3; // 1000

function getBigCurrencyNum(
  numBN: BN,
  unit: 'K' | 'M' | 'B' | 'T',
  roundingMode?: BN.RoundingMode,
): { numBN: BN; numStr: string } {
  if (!roundingMode) roundingMode = BN.ROUND_DOWN;
  const i = CURRENCY_UNITS.findIndex((u) => u === unit);
  let numStr = numBN.toString(10);
  numBN = numBN.div(CURRENCY_UNIT_NUM);
  // get the biggest unit
  if (numBN.gte(CURRENCY_UNIT_NUM) && unit !== 'T') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return getBigCurrencyNum(numBN, CURRENCY_UNITS[i + 1]);
  }
  if (unit === 'T' && numBN.gte(CURRENCY_UNIT_NUM)) {
    numBN = numBN.integerValue(roundingMode);
  } else {
    numBN = numBN.precision(2, roundingMode);
  }
  numStr = `${numBN.toPrecision(2, roundingMode)}${CURRENCY_UNITS[i]}`;

  return {
    numBN,
    numStr,
  };
}

/**
 * number display format
 * @param param0
 * @returns
 */
export function formatDisplayNumber({
  num,
  isShowSeparator = true,
  type = 'normal',
  isOperateNum = false,
  isShowTBMK = false,
  isShowApproximatelyEqualTo = true,
  showPositive,
  roundingMode = BN.ROUND_DOWN,
}: {
  num: BigNumberish | BN;
  isShowSeparator?: boolean;
  type?: 'price' | 'normal';
  isOperateNum?: boolean;
  isShowTBMK?: boolean;
  showPositive?: boolean;
  isShowApproximatelyEqualTo?: boolean;
  roundingMode?: BN.RoundingMode;
}): string {
  let numBN = toBN(num);
  if (numBN.isNaN() || numBN.eq(Infinity)) {
    numBN = toBN(0);
  }
  let sign = '';
  if (numBN.isNegative()) {
    sign = '-';
    numBN = numBN.abs();
  } else if (showPositive) {
    sign = '+';
  }
  let numStr = numBN.toString(10);
  if (isOperateNum) {
    if (numBN.gte(1)) {
      numStr = numBN.toFixed(2, roundingMode);
    } else {
      numStr = numBN.toPrecision(2, roundingMode);
    }
  } else {
    // >=1000 use TBMK unit
    if (numBN.eq(0)) {
      numStr = numBN.toFixed(2, roundingMode);
    } else if (numBN.gte(10000)) {
      const numRes = numBN.integerValue(roundingMode);
      numStr = numRes.toString();
      if (isShowTBMK) {
        const numRes = getBigCurrencyNum(numBN, 'K', roundingMode);
        numStr = numRes.numStr;
      }
    } else if (numBN.gte(1000) && numBN.lt(10000)) {
      if (type === 'price') {
        numBN = numBN.precision(5, roundingMode);
        numStr = numBN.toPrecision(5, roundingMode);
      } else {
        const numRes = numBN.precision(5, roundingMode);
        numStr = numRes.toPrecision(5, roundingMode);
        if (isShowTBMK) {
          const numRes = getBigCurrencyNum(numBN, 'K', roundingMode);
          numStr = numRes.numStr;
        }
      }
    } else if (numBN.gte(1) && numBN.lt(1000)) {
      numBN = numBN.precision(4, roundingMode);
      numStr = numBN.toPrecision(4, roundingMode);
    } else if (numBN.gte(0.0001) && numBN.lt(1)) {
      if (type === 'price') {
        numBN = numBN.precision(5, roundingMode);
        numStr = numBN.toPrecision(5, roundingMode);
      } else {
        numStr = numBN.toFixed(2, roundingMode);
      }
    } else {
      if (type === 'price') {
        numBN = numBN.precision(2, roundingMode);
        const decimalPlaces = numBN.decimalPlaces() || 0;
        if (decimalPlaces > 8) {
          numStr = numBN.toFixed(8, roundingMode);
        } else {
          numStr = numBN.toPrecision(2, roundingMode);
        }
      } else {
        // show `<0.0001` when 0< num < 0.0001
        if (sign !== '-') {
          return `＜0.01`;
        }
        return `${isShowApproximatelyEqualTo ? '≈' : ''}0.00`;
      }
    }
  }

  if (isShowSeparator) {
    numStr = toCurrencyNumber(numStr);
  }
  return `${sign}${numStr}`;
}

// remove zero after point
export function removeTrailingZeros(value: string | number): string {
  value = value.toString();
  if (value.match(/\./)) {
    value = value.replace(/\.?0+$/, '');
  }
  return value;
}

/**
 * number in thousands
 */
const numberWithCommas = (x: number | string): string => {
  const parts = x.toString().split('.');
  if (parts.length > 0) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return '';
};

/**
 * format number, keep 2 decimals by default
 * (optional) comma to separate in thousands
 * @export
 * @param {(number | string)} numBN
 * @param {number} [decimals=DEFAULT_DECIMAL_PLACES]
 * @param {boolean} [isShowSeparator=true]
 * @param {number} [roundingMode=BigNumber.ROUND_DOWN]
 * @param {boolean} [isRemoveTrailingZeros=false]
 * @returns
 */
export function formatNumber(
  num: BigNumberish | BN,
  decimals: number = DEFAULT_DECIMAL_PLACES,
  isShowSeparator = true,
  roundingMode: BN.RoundingMode = BN.ROUND_DOWN,
  isRemoveTrailingZeros = false,
): string {
  let numBN = toBN(num);
  if (
    // num === 'NaN' ||
    // num === undefined ||
    // num.toString().trim() === '' ||
    // Math.abs(Number(num)) === Infinity ||
    // Number.isNaN(Number(num))
    numBN.isNaN() ||
    numBN.eq(Infinity)
  ) {
    numBN = toBN(0);
  }

  let res = numBN.toFixed(decimals, roundingMode);

  if (isShowSeparator) {
    res = numberWithCommas(res);
  }
  if (isRemoveTrailingZeros) {
    res = removeTrailingZeros(res);
  }

  return res;
}

/**
 * make sure the number in range, otherwise convert it
 * make sure input legal num
 * number should between 1e6 and 1e-6 [1e-6, 1e6)
 * @export
 * @param {(number | string)} num the number user input
 * @param {number} [decimals=6]
 * @param {number} [biggestNumber=1e6 - 1e-4]
 * @returns return number with String type
 */
export function inputNumChecker(
  num: number | string,
  decimals = 2,
  allowMinus = false,
  // integerMaxLength = 6
): string {
  num = num.toString();
  // replace illegal digital
  const regex = allowMinus ? /[^0-9.-]/g : /[^0-9.]/g;
  num = num.replace(regex, '');
  const parts = num.split('.');
  // user paste the number contains more then two dot[.]
  if (parts.length >= 3) {
    return '';
  }
  let integerPart = parts[0];
  // remove leading zero except 1 zero
  if (integerPart.length > 0) {
    while (integerPart.length > 1 && integerPart[0] === '0') {
      integerPart = integerPart.substr(1);
    }
  } else if (num.indexOf('.') === 0) {
    integerPart = '0';
  }
  // // max integer length <=7
  // if (integerPart.length > integerMaxLength) {
  //   return (integerPart = integerPart.substr(0, integerMaxLength));
  // }

  let decimalPart = parts[1] || '';
  // only 8 decimal places
  if (decimalPart.length > decimals) {
    decimalPart = decimalPart.substr(0, decimals);
  }
  if (decimalPart.length !== 0) {
    num = `${integerPart}.${decimalPart}`;
  } else {
    // get parse num except single [.]
    num = integerPart + (parts.length === 2 ? '.' : '');
  }
  return num;
}

export function formatEther(num: BigNumber | undefined): string {
  const bn = num || ZERO;

  return utils.formatEther(bn);
}

/**
 * to wad, support float
 * @param wad
 * @returns
 */
export function toWad(num: BigNumberish, decimals = 0): BigNumber {
  return parseUnits(num.toString()).div(10 ** decimals);
}

export function formatPercentageString({
  percentage,
  hundredfold = true,
  decimals = 2,
}: {
  percentage: number | string;
  hundredfold?: boolean;
  decimals?: number;
}): string {
  const percentageNumber = Number(percentage) * (hundredfold ? 100 : 1);
  return `${formatNumber(percentageNumber, decimals)}%`;
}
