import './index.less';

import { Modal as AntDModal } from 'antd';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconClose } from '@/assets/svg/icon_close.svg';
import { IModalProps } from '@/types/modal';

/**
 *  antd modal wrap
 * @param param0 antd modal props
 * @returns
 */
export default function Modal({ className, title, closable = true, ...props }: IModalProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(props.open);
  useEffect(() => setIsOpen(props.open), [props.open]);
  function onClickCancelHandler(): void {
    setIsOpen(false);
  }
  if (!props.open) {
    return <></>;
  }
  return (
    <AntDModal
      width={600}
      footer={null}
      destroyOnClose
      closable={false}
      {...props}
      className={classnames('dapp-modal', className)}
      open={isOpen}
      onOk={(): void => {
        props.onOk && props.onOk();
        onClickCancelHandler();
      }}
      onCancel={onClickCancelHandler}
      afterClose={(): void => {
        props.onCancel && props.onCancel();
      }}>
      {title && (
        <div className="dapp-modal-header">
          <div className="dapp-modal-header-title">
            {title}
            {closable && (
              <div className="dapp-modal-close" onClick={onClickCancelHandler}>
                <IconClose></IconClose>
              </div>
            )}
          </div>
          {props.extraHeader}
        </div>
      )}
      {props.children}
    </AntDModal>
  );
}
