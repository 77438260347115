import { DHKDSdk, FiatToken } from '@derivation-tech/dhkd-sdk';
import { CHAIN_ID } from '@derivation-tech/web3-core';
import { JsonRpcProvider } from '@ethersproject/providers';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { TOKENS } from '@/constants/token';

import { AppState } from '../store';

export const setCurrentToken = createAction<TOKENS>('web3/setCurrentToken');

export const initSDKContext = createAsyncThunk(
  'web3/initSDKContext',

  async (
    { chainId, provider }: { chainId: CHAIN_ID; provider?: JsonRpcProvider },
    { getState, dispatch },
  ): Promise<DHKDSdk | undefined> => {
    const {
      web3: { chainSDKContext },
    } = getState() as AppState;
    try {
      provider && dispatch(setAppProvider({ chainId, provider }));
      if (chainSDKContext[chainId]) {
        return;
      }
      const sdkContext = DHKDSdk.getInstance(chainId);
      sdkContext;
      return sdkContext;
    } catch (e) {
      console.warn(e);
    }
  },
);
export const initContracts = createAsyncThunk(
  'web3/initContracts',

  async (
    { token, provider, sdk }: { chainId: CHAIN_ID; provider?: JsonRpcProvider; token: TOKENS; sdk: DHKDSdk },
    { getState },
  ): Promise<FiatToken | undefined> => {
    const {
      web3: { contracts },
    } = getState() as AppState;
    try {
      if (contracts[token]) {
        return;
      }
      const contract = sdk.getFiatTokenContract(token, provider);
      return contract;
    } catch (e) {
      console.warn(e);
    }
  },
);
export const setAppProvider = createAsyncThunk(
  'web3/setAppProvider',

  (
    { chainId, provider }: { chainId: CHAIN_ID; provider: JsonRpcProvider },
    { getState },
  ): JsonRpcProvider | undefined => {
    const {
      web3: { chainAppProvider },
    } = getState() as AppState;

    if (chainAppProvider[chainId]) {
      return;
    }
    return provider;
  },
);
