/**
 * @description Component-CubicAnimate
 */

import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { useMediaQueryDevice } from '@/components/MediaQuery';

import { Webgl } from './cubic.js';
interface IPropTypes {
  className?: string;
  weglChange: (ok: boolean) => void;
}

const CubicAnimate: FC<IPropTypes> = function ({ className, weglChange }) {
  const cubicRef = useRef<HTMLDivElement>(null);
  const { isBigScreen } = useMediaQueryDevice();
  const webgl = useMemo(() => {
    try {
      return isBigScreen ? new Webgl(758, 682) : new Webgl(578, 520);
    } catch (e) {
      weglChange(false);
      return undefined;
    }
  }, [isBigScreen, weglChange]);

  const animate = useCallback(() => {
    if (webgl) {
      requestAnimationFrame(animate);
      webgl.render();
    }
  }, [webgl]);
  const resizeHandler = useCallback(() => {
    if (webgl) {
      webgl.resize(500, 500);
    }
  }, [webgl]);

  useEffect(() => {
    if (cubicRef?.current && cubicRef.current?.children.length === 0) {
      webgl && cubicRef?.current?.appendChild(webgl.renderer.domElement);
    }
    // document.getElementById('cubic')?.appendChild(stats.domElement);
    window.onresize = resizeHandler;
    animate();
  }, [animate, resizeHandler, webgl, webgl?.renderer.domElement]);
  return <div id="cubic" ref={cubicRef} className={classNames('dapp-cubic-animate', className)}></div>;
};

export default CubicAnimate;
