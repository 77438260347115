import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import balanceReducer from './balance/balanceSlice';
import chainReducer from './chain/chainSlice';
import globalReducer from './global/globalSlice';
import graphReducer from './graph/slice';
import { chainConfigMiddleware } from './middleware';
import transactionReducer from './transaction/transactionSlice';
import userReducer from './user/userSlice';
import walletReducer from './wallet/walletSlice';
import web3Reducer from './web3/web3Slice';

export const PERSISTED_KEYS: string[] = [];

export const storeReducer = {
  user: userReducer,
  chain: chainReducer,
  balance: balanceReducer,
  web3: web3Reducer,
  global: globalReducer,
  transaction: transactionReducer,
  wallet: walletReducer,
  graph: graphReducer,
};

export const store = configureStore({
  reducer: storeReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(chainConfigMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
