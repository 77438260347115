import { CHAIN_ID, ZERO_ADDRESS } from '@derivation-tech/web3-core';
import { useDebounceEffect } from 'ahooks';

import { FETCHING_STATUS } from '@/constants';
import { TOKENS } from '@/constants/token';
import { getTokenBalance } from '@/features/balance/actions';
import { addTransaction, getTxHistory } from '@/features/graph/actions';
import { setUserTxStatus } from '@/features/user/actions';
import { useUserWalletInfo } from '@/features/user/hooks';
import { useContract, useSDKContext } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { TxSide, TxTarget } from '@/types/tx';
import { formatEther } from '@/utils/numberUtil';

// listener for user worker event
export function useUserWorkerEventListener(chainId: CHAIN_ID | undefined): void {
  const walletInfo = useUserWalletInfo();
  const sdkContext = useSDKContext(chainId);
  const DHKDContract = useContract(TOKENS.DHKD);
  const DUSDDContract = useContract(TOKENS.DUSD);

  const dispatch = useAppDispatch();
  useDebounceEffect(() => {
    if (walletInfo?.address) {
      //  when token being mint to address from bank
      [DHKDContract, DUSDDContract].forEach((contract) => {
        const mintFilter = contract?.filters.Mint(null, walletInfo.address);
        if (mintFilter) {
          contract?.on(mintFilter, async (minter, to, amount, note, event) => {
            if (minter !== ZERO_ADDRESS && to !== ZERO_ADDRESS) {
              console.log(
                '🚀 ~ file: useWorkerHooks.ts:29 ~ useDebounceEffect ~ minter:',
                minter,
                to,
                amount,
                note,
                event,
              );
              chainId &&
                dispatch(
                  getTokenBalance({
                    chainId,
                    contract,
                    userAddress: walletInfo.address,
                  }),
                );
              // this happens when deposit from bank
              if (to.toLocaleLowerCase() === walletInfo.address.toLocaleLowerCase()) {
                dispatch(setUserTxStatus(FETCHING_STATUS.DONE));
                chainId && dispatch(getTokenBalance({ chainId, userAddress: walletInfo.address, contract }));
                chainId && sdkContext && dispatch(getTxHistory({ chainId, userAddr: walletInfo.address, sdkContext }));
                const receipt = await event.getTransactionReceipt();
                if (receipt) {
                  chainId &&
                    dispatch(
                      addTransaction({
                        chainId,
                        side: TxSide.DEPOSIT,
                        amount: formatEther(amount),
                        target: TxTarget.BANK,
                        txHash: receipt.transactionHash,
                        logIndex: event.logIndex,
                        timestamp: new Date().getTime(),
                        minter,
                        to,
                        symbol: (await contract.symbol()) as TOKENS,
                      }),
                    );
                }
              }
            }
          });
        }
      });
    }
  }, [sdkContext, DHKDContract, DUSDDContract]);
  useDebounceEffect(() => {
    if (walletInfo?.address) {
      //  when token  send to bank
      [DHKDContract, DUSDDContract].forEach((contract) => {
        const burnFilter = contract?.filters.Burn(null, walletInfo.address);
        if (burnFilter) {
          contract?.on(burnFilter, async (minter, account, amount, note, event) => {
            if (minter !== ZERO_ADDRESS && account !== ZERO_ADDRESS) {
              console.log(
                '🚀 ~ file: useWorkerHooks.ts:29 ~ useDebounceEffect ~ minter:',
                minter,
                account,
                amount,
                note,
                event,
              );
              chainId &&
                dispatch(
                  getTokenBalance({
                    chainId,
                    contract,
                    userAddress: walletInfo.address,
                  }),
                );
              // this happens when send to bank
              if (account.toLocaleLowerCase() === walletInfo.address.toLocaleLowerCase()) {
                dispatch(setUserTxStatus(FETCHING_STATUS.DONE));
                chainId && dispatch(getTokenBalance({ chainId, userAddress: walletInfo.address, contract }));
                chainId && sdkContext && dispatch(getTxHistory({ chainId, userAddr: walletInfo.address, sdkContext }));
                const receipt = await event.getTransactionReceipt();
                if (receipt) {
                  chainId &&
                    dispatch(
                      addTransaction({
                        chainId,
                        side: TxSide.SEND,
                        amount: formatEther(amount),
                        target: TxTarget.BANK,
                        txHash: receipt.transactionHash,
                        logIndex: event.logIndex,
                        timestamp: new Date().getTime(),
                        minter,
                        account,
                        symbol: (await contract.symbol()) as TOKENS,
                      }),
                    );
                }
              }
            }
          });
        }
      });
    }
  }, [sdkContext, DHKDContract, DUSDDContract]);
  useDebounceEffect(() => {
    if (walletInfo?.address) {
      //  when token is being transferred from address
      [DHKDContract, DUSDDContract].forEach((contract) => {
        const transferFilter = contract?.filters.Transfer(walletInfo.address, null);
        if (transferFilter) {
          contract?.on(transferFilter, async (from, to, amount, event) => {
            if (from !== ZERO_ADDRESS && to !== ZERO_ADDRESS) {
              console.log('🚀 ~ file: useWorkerHooks.ts:29 ~ useDebounceEffect ~ minter:', from, to, amount, event);
              chainId &&
                dispatch(
                  getTokenBalance({
                    chainId,
                    contract,
                    userAddress: walletInfo.address,
                  }),
                );
              // this happens when send to wallet
              if (from.toLocaleLowerCase() === walletInfo.address.toLocaleLowerCase()) {
                dispatch(setUserTxStatus(FETCHING_STATUS.DONE));
                chainId && dispatch(getTokenBalance({ chainId, userAddress: walletInfo.address, contract }));
                chainId && sdkContext && dispatch(getTxHistory({ chainId, userAddr: walletInfo.address, sdkContext }));
                const receipt = await event.getTransactionReceipt();
                if (receipt) {
                  chainId &&
                    dispatch(
                      addTransaction({
                        chainId,
                        side: TxSide.SEND,
                        amount: formatEther(amount),
                        target: TxTarget.WALLET,
                        txHash: receipt.transactionHash,
                        logIndex: event.logIndex,
                        timestamp: new Date().getTime(),
                        from,
                        to,
                        symbol: (await contract.symbol()) as TOKENS,
                      }),
                    );
                }
              }
            }
          });
        }
      });
    }
  }, [sdkContext, DHKDContract, DUSDDContract]);
  useDebounceEffect(() => {
    if (walletInfo?.address) {
      //  when token is being transferred to address
      [DHKDContract, DUSDDContract].forEach((contract) => {
        const transferFilter = contract?.filters.Transfer(null, walletInfo.address);
        if (transferFilter) {
          contract?.on(transferFilter, async (from, to, amount, event) => {
            if (from !== ZERO_ADDRESS && to !== ZERO_ADDRESS) {
              console.log('🚀 ~ file: useWorkerHooks.ts:29 ~ useDebounceEffect ~ minter:', from, to, amount, event);
              chainId &&
                dispatch(
                  getTokenBalance({
                    chainId,
                    contract,
                    userAddress: walletInfo.address,
                  }),
                );
              if (to.toLocaleLowerCase() === walletInfo.address.toLocaleLowerCase()) {
                chainId && dispatch(getTokenBalance({ chainId, userAddress: walletInfo.address, contract }));
                chainId && sdkContext && dispatch(getTxHistory({ chainId, userAddr: walletInfo.address, sdkContext }));
                const receipt = await event.getTransactionReceipt();
                if (receipt) {
                  chainId &&
                    dispatch(
                      addTransaction({
                        chainId,
                        side: TxSide.DEPOSIT,
                        amount: formatEther(amount),
                        target: TxTarget.WALLET,
                        txHash: receipt.transactionHash,
                        logIndex: event.logIndex,
                        timestamp: new Date().getTime(),
                        from,
                        to,
                        symbol: (await contract.symbol()) as TOKENS,
                      }),
                    );
                }
              }
            }
          });
        }
      });
    }
  }, [sdkContext, DHKDContract, DUSDDContract]);
}
