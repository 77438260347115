/**
 * @description Component-
 */
import { Tooltip, TooltipProps } from 'antd';
import React, { FC, memo } from 'react';

import { useMediaQueryDevice } from '@/components/MediaQuery';

const ResponsiveTooltip: FC<TooltipProps> = function (props) {
  const { isMobile } = useMediaQueryDevice();
  return isMobile ? <> {props.children}</> : <Tooltip {...props} />;
};

export default memo(ResponsiveTooltip);
