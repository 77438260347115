import './index.less';

import classNames from 'classnames';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function PageLayout({ className }: { className?: string }): JSX.Element {
  return (
    <div className={classNames(className, 'page-layout')}>
      <Outlet></Outlet>
    </div>
  );
}
