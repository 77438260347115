import { Network } from '@web3-react/network';
import { Connector, Provider } from '@web3-react/types';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { appConnector } from '@/connectors/appConnector';
import { metaMaskConnector } from '@/connectors/metaMask';
import { WebSocketConnector } from '@/connectors/WebSocketConnector';
import { APP_CHAIN_ID } from '@/constants/storage';
// import { useGa } from '@/hooks/useGa';
import { useTxNotification } from '@/hooks/useTxNotification';
// import { useWalletConnector, useWalletProvider } from '@/hooks/web3/useWalletNetwork';
import { useAppProvider } from '@/hooks/web3/useWeb3Network';
import { instanceOfProviderRpcError, IProviderRpcError } from '@/types/error';
import { getAddChainParameters } from '@/utils/chain';
export function useSwitchNetwork(connector: Connector | undefined): {
  switchChain: (desiredChainId: number) => Promise<boolean>;
  switchNetworkFromProvider: (
    chainId: number,
    provider: Provider,
  ) => Promise<{
    isSucceed: boolean;
    error: IProviderRpcError | undefined;
  }>;
} {
  const { error: errorNotify } = useTxNotification();
  const { t } = useTranslation();
  /**const { open } = useTxNotification();
   * add network config to metamask
   */
  const addNetwork = useCallback(
    async (desiredChainId: number, provider: Provider) => {
      let isSucceed = false;
      let error: IProviderRpcError | undefined = undefined;
      try {
        const chainParam = getAddChainParameters(desiredChainId, connector === metaMaskConnector);
        if (typeof chainParam !== 'number') {
          chainParam.chainId = `0x${desiredChainId.toString(16)}` as unknown as number;
        }

        console.warn("🚀 try call 'wallet_addEthereumChain'", chainParam);

        provider?.request &&
          (await provider.request({
            method: 'wallet_addEthereumChain',
            params: [chainParam],
          }));
        isSucceed = true;
      } catch (addError) {
        console.error('🚀 ~ file: useSwitchConnectorNetwork.tsx ~ line 40 ~ addError', addError);
        if (instanceOfProviderRpcError(addError)) error = addError;

        // handle "add" error
      }
      return { isSucceed, error };
    },
    [connector],
  );

  /**
   * switch network or add it to metamask
   */
  const switchNetworkFromProvider = useCallback(
    async (desiredChainId: number, provider: Provider) => {
      let isSucceed = false;
      let error: IProviderRpcError | undefined = undefined;
      try {
        console.warn("🚀   try call 'wallet_switchEthereumChain'", {
          chainId: `0x${desiredChainId.toString(16)}`,
        });
        provider?.request &&
          (await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${desiredChainId.toString(16)}` }],
          }));
        isSucceed = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (switchError: any) {
        console.error('🚀 ~ file: useSwitchConnectorNetwork.tsx ~ line 65 ~ switchError', switchError, {
          errorCode: switchError?.code,
        });
        // adapt metamask mobile error code
        if (
          switchError?.code === 4902 ||
          (isMobile && (switchError?.data?.orginalError?.code === 4902 || switchError?.code === -32603))
        ) {
          // if network is not exist on metamask, then add it to metamask
          const res = await addNetwork(desiredChainId, provider);
          return res;
        } else {
          if (instanceOfProviderRpcError(switchError)) error = switchError;

          errorNotify({
            message: t('errors.wallet.switchNetworkError'),
            link: 'https://help.1inch.io/en/articles/7244468-metamask-switch-networks-bug',
          });
        }
      }
      return { isSucceed, error };
    },
    [addNetwork, errorNotify, t],
  );

  const switchChain = useCallback(
    async (desiredChainId: number) => {
      if (!connector) return false;
      try {
        const chainParam = getAddChainParameters(desiredChainId, connector === metaMaskConnector);
        if (typeof chainParam !== 'number') {
          chainParam.chainId = `0x${desiredChainId.toString(16)}` as unknown as number;
        }
        if (
          connector instanceof WalletConnect ||
          connector instanceof Network ||
          connector instanceof WebSocketConnector
        ) {
          await connector.activate(desiredChainId === -1 ? undefined : desiredChainId);
          // } else if (connector === bitKeepConnector) {
          //   connector.provider && switchNetworkFromProvider(desiredChainId, connector.provider);
        } else {
          connector.provider && (await switchNetworkFromProvider(desiredChainId, connector.provider));
          // await connector.activate(desiredChainId === -1 ? undefined : chainParam);
        }
        if (!(connector instanceof Network || connector instanceof WebSocketConnector)) {
          console.record('wallet', 'Switch network', true, {
            desiredChainId,
            chainParam: chainParam,
            connector: connector,
            isAppNetwork: connector instanceof Network || connector instanceof WebSocketConnector,
          });
        }

        return true;
      } catch (error) {
        console.error('🚀 ~ file: useNetwork.ts ~ line 117 ~ async ~ error', error);
      }
      return false;
    },
    [connector, switchNetworkFromProvider],
  );
  return { switchChain, switchNetworkFromProvider };
}

export function useSwitchAppAndWalletNetwork(): (desiredChainId: number) => Promise<void> {
  const appProvider = useAppProvider();
  // const walletProvider = useWalletProvider();
  // const walletConnector = useWalletConnector();
  // const { switchChain: switchNetwork } = useSwitchNetwork(walletConnector);
  const { switchChain: switchAppNetwork } = useSwitchNetwork(appConnector);
  // const gaEvent = useGa();

  return useCallback(
    async (desiredChainId: number) => {
      // switch local network
      if (appProvider) {
        console.log('🚀 ~ file: useSwitchConnectorNetwork.tsx:162 ~ appProvider:', appProvider);
        switchAppNetwork(desiredChainId).then((isSucceed) => {
          if (isSucceed) {
            // save chainId to localforage
            localStorage.setItem(APP_CHAIN_ID, desiredChainId.toString());
          }
        });
      }
      // // also switch wallet network
      // if (walletProvider) {
      //   const isSucceed = await switchNetwork(desiredChainId);
      //   console.record('wallet', 'Switch network', isSucceed, {
      //     desiredChainId,
      //   });
      //   gaEvent({
      //     category: GaCategory.HEADER,
      //     action: 'click switch network',
      //     label: { targetNetWork: desiredChainId },
      //   });
      // }
    },
    [appProvider, switchAppNetwork],
  );
}
