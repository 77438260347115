import { Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { Network } from '@web3-react/network';
import { WalletConnect } from '@web3-react/walletconnect-v2';

import { SUPPORTED_WALLETS } from '@/constants/wallets';

import { appConnector, appConnectorHooks } from './appConnector';
import { WebSocketConnector } from './WebSocketConnector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tuple<T extends any[]>(...elements: T) {
  return elements;
}

type WalletConnectorType = MetaMask | WalletConnect | Network | WebSocketConnector;

const config = Object.values(SUPPORTED_WALLETS).map((wallet) => {
  return tuple(wallet.connector as WalletConnectorType, wallet.hooks);
});

export const connectors: [WalletConnectorType, Web3ReactHooks][] = [...config, [appConnector, appConnectorHooks]];
