import BN from 'bignumber.js';
import { BigNumber } from 'ethers';
import { has as _has } from 'lodash';
import { ReactNode } from 'react';

import {
  formatNumberWithTooltip,
  FormatNumberWithTooltipType,
  formatPercentage,
  formatPriceNumberWithTooltip,
} from '@/components/NumberFormat';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';
import {
  formatDisplayNumber,
  formatEther,
  formatNumber,
  formatPercentageString,
  inputNumChecker,
  toWad,
} from '@/utils/numberUtil';

// BN decimal config
BN.config({ DECIMAL_PLACES: 18 });

export type WrappedBigNumberLike = WrappedBigNumber | BigNumber | string | number | BN;

/**
 * BigNumber Wrapper
 * include ethers.BigNumber and BN with decimal places
 * and a lot of format func
 */
export class WrappedBigNumber extends BN {
  constructor(value: BigNumber | number | string | BN) {
    if (value instanceof BN) {
      super(value);
    } else if (value instanceof BigNumber) {
      super(formatEther(value));
    } else if (_has(value, '_hex')) {
      super(formatEther(BigNumber.from(value)));
    } else {
      super(value?.toString() || '');
    }
  }

  /**
   * zero of wrapped type
   */
  static get ZERO(): WrappedBigNumber {
    return new WrappedBigNumber(0);
  }

  static from(value: WrappedBigNumberLike): WrappedBigNumber {
    if (value instanceof WrappedBigNumber) {
      return value;
    }
    return new WrappedBigNumber(value);
  }

  get wadValue(): BigNumber {
    return toWad(this.toString());
  }

  get displayValue(): string {
    return formatDisplayNumber({ num: this });
  }
  get stringValue(): string {
    return this.toString();
  }
  get sqrtVal(): WrappedBigNumber {
    return WrappedBigNumber.from(this.sqrt());
  }

  get reciprocal(): WrappedBigNumber {
    return WrappedBigNumber.from(1).div(this);
  }

  decimalValue(decimal: number): WrappedBigNumber {
    return WrappedBigNumber.from(inputNumChecker(this.stringValue, decimal));
  }

  // TODO: add plus/minus/times/div wrapper
  abs(): WrappedBigNumber {
    return WrappedBigNumber.from(super.abs());
  }

  add(n: WrappedBigNumberLike): WrappedBigNumber {
    return WrappedBigNumber.from(super.plus(WrappedBigNumber.from(n)));
  }

  min(n: WrappedBigNumberLike): WrappedBigNumber {
    return WrappedBigNumber.from(super.minus(WrappedBigNumber.from(n)));
  }

  mul(n: WrappedBigNumberLike): WrappedBigNumber {
    return WrappedBigNumber.from(super.multipliedBy(WrappedBigNumber.from(n)));
  }

  div(n: BN.Value): WrappedBigNumber {
    return WrappedBigNumber.from(super.div(n));
  }

  gte(n: BN.Value): boolean {
    return super.isGreaterThanOrEqualTo(n);
  }

  /**
   *
   * @returns 0 if this < 0
   */
  keepPositive(): WrappedBigNumber {
    return this.lt(0) ? WrappedBigNumber.from(0) : this;
  }

  notEq(n: BN.Value): boolean {
    return !this.eq(n);
  }

  formatWithToolTip(): ReactNode {
    return formatNumberWithTooltip({ num: this });
  }

  formatPriceNumberWithTooltip(options: Omit<FormatNumberWithTooltipType, 'num'> = {}): ReactNode {
    return formatPriceNumberWithTooltip({ num: this, ...options });
  }

  formatNumberWithTooltip(options: Omit<FormatNumberWithTooltipType, 'num'> = {}): ReactNode {
    return formatNumberWithTooltip({
      num: this,
      ...options,
    });
  }
  formatDisplayNumber(
    options: {
      isShowSeparator?: boolean;
      type?: 'price' | 'normal';
      isOperateNum?: boolean;
      isShowTBMK?: boolean;
      roundingMode?: BN.RoundingMode;
      isShowApproximatelyEqualTo?: boolean;
    } = {},
  ): string {
    return formatDisplayNumber({
      num: this,
      ...options,
    });
  }

  formatPercentage(options?: {
    hundredfold?: boolean;
    colorShader?: boolean;
    customTitle?: ReactNode;
    decimals?: number;
  }): ReactNode {
    return formatPercentage({
      percentage: this.toString(10),
      ...options,
    });
  }

  formatPercentageString(hundredfold = true, decimals = 2): string {
    return formatPercentageString({ percentage: this.toString(10), hundredfold, decimals });
  }

  formatNormalNumberString(
    decimals: number = DEFAULT_DECIMAL_PLACES,
    isShowSeparator = true,
    roundingMode: BN.RoundingMode = BN.ROUND_DOWN,
    isRemoveTrailingZeros = false,
  ): string {
    return formatNumber(this, decimals, isShowSeparator, roundingMode, isRemoveTrailingZeros);
  }

  toDisplayJSON(): {
    wadValue: BigNumber;
    numStr: string;
    displayValue: string;
  } {
    return {
      wadValue: this.wadValue,
      numStr: this.toString(10),
      displayValue: this.displayValue,
    };
  }

  valueOf(): string {
    return JSON.stringify(this.toDisplayJSON());
  }

  toJSON(): string {
    return this.valueOf();
  }

  toString(base?: number | undefined): string {
    return super.toString(base || 10);
  }
}
