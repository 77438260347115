/**
 * @description Component-AmountInput
 */
import './index.less';

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@/components/Input';
import { SEND_PAGES } from '@/constants/global';
import { useCurrentToken } from '@/features/web3/hook';
import { inputNumChecker } from '@/utils/numberUtil';

import BalanceMax from '../BalanceMax';
interface IPropTypes {
  className?: string;
  onChange: (value: string) => void;
  type?: SEND_PAGES;
  balance: number;
  value?: string;
}
const AmountInput: FC<IPropTypes> = function ({ onChange, type, balance, value }) {
  const { t } = useTranslation();
  const token = useCurrentToken();
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    value && setInputValue(value);
  }, [value]);
  return (
    <div className="dapp-amount-input">
      <div className="dapp-amount-input-title">{t('common.amountInput.title')}</div>
      <div className="dapp-amount-input-input">
        <div className="dapp-amount-input-input-header">
          <div className="dapp-amount-input-input-header-left">
            {type === SEND_PAGES.WALLET ? t('common.amountInput.titleLine2') : t('common.amountInput.titleLine')}
          </div>
          <BalanceMax
            balance={balance}
            onClick={() => {
              setInputValue(balance.toString());
              onChange(balance.toString());
            }}
          />
        </div>
        <Input
          value={inputValue}
          onChange={(e) => {
            onChange(inputNumChecker(e.target.value, 2));
            setInputValue(inputNumChecker(e.target.value, 2));
          }}
          suffix={token}
        />
      </div>
    </div>
  );
};

export default AmountInput;
