/**
 * @description Component-SendToBank
 */
import './index.less';

import { Form } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { ReactComponent as ErrorIcon } from '@/assets/svg/icon_error.svg';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo_color_dark.svg';
import { Button } from '@/components/Button';
import Modal from '@/components/Modal';
import { FETCHING_STATUS } from '@/constants';
import { BANK_INFO } from '@/constants/global';
import { WrappedBigNumber } from '@/entities/WrappedBigNumber';
import { useTokenBalanceByChainIdAndToken } from '@/features/balance/hook';
import { sendToBank, setUserTxStatus } from '@/features/user/actions';
import { useUserTxStatus, useUserWalletInfo } from '@/features/user/hooks';
import { useContract, useCurrentToken } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import AmountInput from '@/pages/components/AmountInput';
import BankRecipientSelector from '@/pages/components/BankRecipientSelector';
import { inputNumChecker } from '@/utils/numberUtil';

import SendSuccess from './SendSuccess';
interface IPropTypes {
  className?: string;
}
const SendToBank: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [bank, setBank] = useState(BANK_INFO);
  const dispatch = useAppDispatch();
  const currentToken = useCurrentToken();
  const walletInfo = useUserWalletInfo();
  const contract = useContract(currentToken);
  const chainId = useChainId();
  const balance = useTokenBalanceByChainIdAndToken(currentToken, chainId);
  const insufficient = useMemo(() => (amount ? WrappedBigNumber.from(balance).lt(amount) : false), [balance, amount]);

  const txStatus = useUserTxStatus();
  const send = useCallback(async () => {
    if (chainId && contract && bank && amount && walletInfo) {
      await dispatch(
        sendToBank({
          chainId,
          contractAddr: contract.address,
          to: bank,
          username: walletInfo.username,
          amount: Number(amount),
        }),
      ).unwrap();
      setAmount('');
    }
  }, [amount, bank, chainId, contract, dispatch, walletInfo]);
  return (
    <>
      <Button
        onClick={() => {
          dispatch(setUserTxStatus(FETCHING_STATUS.INIT));
          setOpen(true);
          setAmount('');
        }}
        className="dapp-btn-sm"
        type="ghost">
        {' '}
        {t('common.detail.bank')}
      </Button>
      <Modal
        open={open}
        title={false}
        footer={false}
        closable={false}
        closeIcon={false}
        width={window.innerWidth}
        onCancel={() => setOpen(false)}
        onOk={() => txStatus !== FETCHING_STATUS.FETCHING && setOpen(false)}
        className="dapp-send-to-bank full">
        <Form onFinish={send}>
          <div className="dapp-send-to-bank-header">
            <LogoIcon />
            <span className="title">{t('common.sendPage.bankTitle')}</span>
            <Button type="text" icon={<CloseIcon onClick={() => setOpen(false)} />} />
          </div>
          {txStatus === FETCHING_STATUS.DONE ? (
            <SendSuccess onConfirm={() => setOpen(false)} />
          ) : (
            <div className="dapp-send-to-bank-content">
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: (
                      <div className="dapp-send-to-bank-content-error">
                        <ErrorIcon />
                        {t('common.sendPage.amountRequired')}
                      </div>
                    ),
                  },
                  {
                    message: (
                      <div className="dapp-send-to-bank-content-error">
                        <ErrorIcon /> {t('common.sendPage.insufficientBal')}
                      </div>
                    ),
                    validator: async () => (insufficient ? Promise.reject() : Promise.resolve()),
                  },
                ]}>
                <AmountInput
                  balance={balance}
                  onChange={(value) => {
                    setAmount(inputNumChecker(value));
                  }}
                />
              </Form.Item>
              <BankRecipientSelector onChange={setBank} />
              <Button
                htmlType="submit"
                disabled={txStatus === FETCHING_STATUS.FETCHING}
                loading={txStatus === FETCHING_STATUS.FETCHING}
                type="primary">
                {txStatus === FETCHING_STATUS.FETCHING ? t('common.sending') : t('common.send')}
              </Button>
            </div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SendToBank;
