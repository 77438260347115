import main from './indexWrap';
import App from './pages/App';
import * as serviceWorkerRegistration from './serviceWorker/serviceWorkerRegistration';
import { reportWebVitals, sendWebVitalsToAnalytics } from './utils/webVitals';

serviceWorkerRegistration.unregister();
main(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
reportWebVitals(sendWebVitalsToAnalytics);
