/**
 * @description Component-SendSuccess
 */
import './index.less';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as OkIcon } from '@/assets/svg/icon_ok_round.svg';
import { Button } from '@/components/Button';
// import { FETCHING_STATUS } from '@/constants';
// import { setUserTxStatus } from '@/features/user/actions';
// import { useAppDispatch } from '@/hooks';
interface IPropTypes {
  className?: string;
  onConfirm: () => void;
}
const SendSuccess: FC<IPropTypes> = function ({ onConfirm }) {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  // const confirm = useCallback(() => {
  //   dispatch(setUserTxStatus(FETCHING_STATUS.INIT));
  //   onConfirm();
  // }, [dispatch, onConfirm]);

  return (
    <div className="dapp-send-success">
      <OkIcon />
      <div className="dapp-send-success-title">{t('common.sent')}</div>
      <Button onClick={onConfirm} type="primary">
        {t('common.confirm')}
      </Button>
    </div>
  );
};

export default SendSuccess;
