/**
 * @description Component-SideMenu
 */
import './index.less';

import { Tooltip } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ReactComponent as ApiIcon } from '@/assets/svg/icon_menu_api.svg';
import { ReactComponent as ApiSolidIcon } from '@/assets/svg/icon_menu_api_solid.svg';
import { ReactComponent as EarnIcon } from '@/assets/svg/icon_menu_earn.svg';
import { ReactComponent as EarnSolidIcon } from '@/assets/svg/icon_menu_earn_solid.svg';
import { ReactComponent as HomeIcon } from '@/assets/svg/icon_menu_home.svg';
import { ReactComponent as HomeSolidIcon } from '@/assets/svg/icon_menu_home_solid.svg';
import { ReactComponent as RecipientIcon } from '@/assets/svg/icon_menu_rec.svg';
import { ReactComponent as RecipientSolidIcon } from '@/assets/svg/icon_menu_rec_solid.svg';
import { ReactComponent as SettingIcon } from '@/assets/svg/icon_menu_setting.svg';
import { ReactComponent as SettingSolidIcon } from '@/assets/svg/icon_menu_setting_solid.svg';
import { ReactComponent as TradeIcon } from '@/assets/svg/icon_menu_trade.svg';
import { ReactComponent as TradeSolidIcon } from '@/assets/svg/icon_menu_trade_solid.svg';
import { ReactComponent as LgoColor } from '@/assets/svg/logo_color.svg';
import { HOME_MENU_KEYS } from '@/constants/menu';
interface IPropTypes {
  className?: string;
}

const SideMenu: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeMenuKey, setActiveMenuKey] = useState<React.Key>(HOME_MENU_KEYS.HOME);
  const getItem = useCallback(
    (label: React.ReactNode, key: React.Key, icon: React.ReactNode, itemIcon: React.ReactNode, disabled?: boolean) => ({
      disabled,
      key,
      component: (
        <div
          onClick={() => {
            if (!disabled) {
              navigate(key.toString().toLowerCase());
              setActiveMenuKey(key);
            }
          }}
          className={`dapp-side-menu-header-menu-item ${activeMenuKey === key && 'selected'} ${
            disabled ? 'disabled' : ''
          }`}
          // to={disabled ? HOME_MENU_KEYS.HOME : `/${key.toString().toLowerCase()}`}
          key={key}>
          {icon}
          {itemIcon}
          {label}
        </div>
      ),
    }),
    [activeMenuKey, navigate],
  );
  const items = useMemo(
    () => [
      getItem(
        t('common.home.sideMenu.home'),
        HOME_MENU_KEYS.HOME,
        <HomeSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <HomeIcon className="dapp-side-menu-header-menu-item-icon" />,
      ),
      getItem(
        t('common.home.sideMenu.rec'),
        HOME_MENU_KEYS.RECIPIENTS,
        <RecipientSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <RecipientIcon className="dapp-side-menu-header-menu-item-icon" />,
        true,
      ),
      getItem(
        t('common.home.sideMenu.trade'),
        HOME_MENU_KEYS.TRADE,
        <TradeSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <TradeIcon className="dapp-side-menu-header-menu-item-icon" />,
        true,
      ),
      getItem(
        t('common.home.sideMenu.earn'),
        HOME_MENU_KEYS.EARN,
        <EarnSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <EarnIcon className="dapp-side-menu-header-menu-item-icon" />,
        true,
      ),
      getItem(
        t('common.home.sideMenu.api'),
        HOME_MENU_KEYS.API,
        <ApiSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <ApiIcon className="dapp-side-menu-header-menu-item-icon" />,
        true,
      ),
      getItem(
        t('common.home.sideMenu.setting'),
        HOME_MENU_KEYS.SETTING,
        <SettingSolidIcon className="dapp-side-menu-header-menu-item-icon solid" />,
        <SettingIcon className="dapp-side-menu-header-menu-item-icon" />,
        true,
      ),
    ],
    [getItem, t],
  );

  return (
    <div className="dapp-side-menu">
      <div className="dapp-side-menu-header">
        <Link to="/">
          <LgoColor />
        </Link>
        <div className="dapp-side-menu-header-menu">
          {items.map((item) =>
            item.disabled ? (
              <Tooltip key={item.key} title={t('common.home.sideMenu.disableTitle')}>
                {item.component}
              </Tooltip>
            ) : (
              <>{item.component}</>
            ),
          )}
        </div>
      </div>
      <div className="dapp-side-menu-footer"></div>
    </div>
  );
};

export default SideMenu;
