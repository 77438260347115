import { BigNumber } from 'ethers';
import _ from 'lodash';

/**
 * polling func
 * @param {Function} func
 * @param {Number} interval
 */
export const pollingFunc = (func: () => void, interval = 3000, immediately = true): NodeJS.Timeout => {
  if (immediately) {
    func();
  }
  return setInterval(() => {
    func();
  }, interval);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function bigNumberObjectCheck(obj: any): any {
  const cloneObj = _.clone(obj);
  if (cloneObj) {
    Object.keys(cloneObj).forEach((key) => {
      const val = cloneObj[key];
      //reset bignumber if number is not correct class
      if (val?._hex && BigNumber.isBigNumber(val) && !(val instanceof BigNumber)) {
        cloneObj[key] = BigNumber.from(val);
      } else if (val?.hex && val?.type === 'BigNumber') {
        cloneObj[key] = BigNumber.from(val);
      } else if (typeof val === 'object' && Object.keys(val).length > 0) {
        cloneObj[key] = bigNumberObjectCheck(val);
      }
    });
  }
  return cloneObj;
}
