import { DHKDSdk, FiatToken } from '@derivation-tech/dhkd-sdk';
import { JsonRpcProvider } from '@ethersproject/providers';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { TOKENS } from '@/constants/token';

import { AppState } from '../store';
import { initContracts, initSDKContext, setAppProvider, setCurrentToken } from './actions';

export interface IWeb3State {
  chainSDKContext: {
    [chainId: number]: DHKDSdk;
  };
  chainAppProvider: {
    [chainId: number]: JsonRpcProvider;
  };
  contracts: {
    [token: string]: FiatToken;
  };
  currentToken: TOKENS;
}

export const initialState: IWeb3State = {
  chainSDKContext: {},
  chainAppProvider: {},
  contracts: {},
  currentToken: TOKENS.DHKD,
};

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initSDKContext.fulfilled, (state, action) => {
      const { arg } = action.meta;
      if (action.payload) {
        _.set(state.chainSDKContext, [arg.chainId], action.payload);
      }
    });

    builder
      .addCase(setAppProvider.fulfilled, (state, action) => {
        const { arg } = action.meta;
        if (action.payload) {
          _.set(state.chainAppProvider, [arg.chainId], action.payload);
          const sdkContext = _.get(state.chainSDKContext, [arg.chainId]);
          if (sdkContext) {
            sdkContext.ctx.provider = action.payload;
          }
        }
      })
      .addCase(initContracts.fulfilled, (state, action) => {
        const { arg } = action.meta;
        if (action.payload) {
          _.set(state.contracts, [arg.token], action.payload);
        }
      })
      .addCase(setCurrentToken, (state, action) => {
        if (action.payload) {
          state.currentToken = action.payload;
        }
      });
  },
});

export const selectWeb3State = (state: AppState): IWeb3State => state.web3;

export const selectSDKContextState =
  (chainId: number | undefined): ((state: AppState) => DHKDSdk | undefined) =>
  (state: AppState): DHKDSdk | undefined =>
    chainId ? state.web3.chainSDKContext[chainId] : undefined;
export const selectCurrentToken = (state: AppState): TOKENS => state.web3.currentToken;
export const selectContract =
  (token: TOKENS | undefined): ((state: AppState) => FiatToken | undefined) =>
  (state: AppState): FiatToken | undefined =>
    _.get(state.web3.contracts, [token || '']);

export default web3Slice.reducer;
