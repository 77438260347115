import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { AppDispatch, AppState } from '@/features/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export function useIsDetailPage(): boolean {
  const location = useLocation();
  const params = location.pathname.split('/');
  const isDetailPage = useMemo(() => {
    return params.length > 3;
  }, [params.length]);
  return isDetailPage;
}

export function useIsPlainBgPage(): boolean {
  const location = useLocation();
  return useMemo(() => {
    return location?.pathname === '/dashboard';
  }, [location?.pathname]);
}
