import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUS } from '@/constants';
import { IUserWallet } from '@/types/user';

import { AppState } from '../store';
import { login, receiveFromBank, sendToBank, sendToWallet, setUserTxStatus, setUserWalletInfo } from './actions';

export interface IUserState {
  currentUserAddr: string;
  prevUserAddr: string;
  userWalletInfo: IUserWallet | undefined;
  loginStatus: FETCHING_STATUS;
  userTxStatus: FETCHING_STATUS;
}

export const initialState: IUserState = {
  currentUserAddr: '',
  prevUserAddr: '',
  userWalletInfo: undefined,
  loginStatus: FETCHING_STATUS.INIT,
  userTxStatus: FETCHING_STATUS.INIT,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserAddr: (state, { payload: { account } }) => {
      account = account.toLowerCase();
      if (state.prevUserAddr === '') {
        state.prevUserAddr = account;
      }
      state.currentUserAddr = account;
    },
    setPrevUserAddr: (state, { payload: { account } }) => {
      account = account.toLowerCase();
      state.prevUserAddr = account || state.currentUserAddr;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserWalletInfo, (state, { payload }) => {
        if (payload) {
          state.userWalletInfo = payload;
        }
      })
      .addCase(login.pending, (state) => {
        state.loginStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.userWalletInfo = payload;
        state.loginStatus = FETCHING_STATUS.DONE;
      })
      .addCase(login.rejected, (state) => {
        state.loginStatus = FETCHING_STATUS.DONE;
      })
      .addCase(setUserTxStatus, (state, action) => {
        state.userTxStatus = action.payload;
      })
      .addCase(sendToBank.pending, (state) => {
        state.userTxStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(sendToBank.fulfilled, () => {
        // state.userOperatingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(sendToBank.rejected, (state) => {
        state.userTxStatus = FETCHING_STATUS.DONE;
      })
      .addCase(sendToWallet.pending, (state) => {
        state.userTxStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(sendToWallet.fulfilled, () => {
        // state.userOperatingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(sendToWallet.rejected, (state) => {
        state.userTxStatus = FETCHING_STATUS.DONE;
      })
      .addCase(receiveFromBank.pending, (state) => {
        state.userTxStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(receiveFromBank.fulfilled, (state) => {
        state.userTxStatus = FETCHING_STATUS.DONE;
      })
      .addCase(receiveFromBank.rejected, (state) => {
        state.userTxStatus = FETCHING_STATUS.DONE;
      });
  },
});

export const { setCurrentUserAddr, setPrevUserAddr } = userSlice.actions;

export const selectUserState = (state: AppState): IUserState => state.user;
export const selectUserWalletInfo = (state: AppState): IUserWallet | undefined => state.user.userWalletInfo;
export const selectLoginStatus = (state: AppState): FETCHING_STATUS => state.user.loginStatus;
export const selectUseTxStatus = (state: AppState): FETCHING_STATUS => state.user.userTxStatus;
export const selectCurrentUserAddr = (state: AppState): string => state.user.currentUserAddr;
export default userSlice.reducer;
