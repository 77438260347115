import type { Networkish } from '@ethersproject/networks';
import type { BaseProvider, Web3Provider } from '@ethersproject/providers';
import { Web3ReactHooks } from '@web3-react/core';
import { Connector } from '@web3-react/types';
import { useMemo } from 'react';

import { metaMaskConnector, metaMaskConnectorHooks } from '@/connectors/metaMask';
import { CHAIN_ID } from '@/constants/chain';
import { SUPPORTED_WALLETS, WalletType } from '@/constants/wallets';
import { useWalletType } from '@/features/wallet/hook';
import { getDefaultChainId } from '@/utils/chain';

import { useAppChainId } from './useWeb3Network';

export function useWalletConnector(): Connector {
  const walletType = useWalletType();
  return useMemo(() => {
    const wallet = SUPPORTED_WALLETS[walletType] || SUPPORTED_WALLETS[WalletType.METAMASK];
    return wallet.connector || metaMaskConnector;
  }, [walletType]);
}

export function useWalletHooks(): Web3ReactHooks {
  const walletType = useWalletType();
  return useMemo(() => {
    const wallet = SUPPORTED_WALLETS[walletType] || SUPPORTED_WALLETS[WalletType.METAMASK];
    return wallet.hooks || metaMaskConnectorHooks;
  }, [walletType]);
}

export function useWalletProvider<T_1 extends BaseProvider = Web3Provider>(
  network?: Networkish | undefined,
  enabled?: boolean | undefined,
): T_1 | undefined {
  const walletHooks = useWalletHooks();
  return walletHooks.useProvider(network, enabled);
}
export const useWalletChainId = (): CHAIN_ID | undefined => {
  const walletHooks = useWalletHooks();
  return walletHooks.useChainId();
};
export const useWalletAccount = (): string | undefined => {
  const walletHooks = useWalletHooks();
  return walletHooks.useAccount();
};
export const useWalletIsActive = (): boolean => {
  const walletHooks = useWalletHooks();
  return walletHooks.useIsActive();
};
export const useWalletActiveByType = (type: WalletType): boolean => {
  const wallet = SUPPORTED_WALLETS[type] || SUPPORTED_WALLETS[WalletType.METAMASK];
  const walletHooks = wallet.hooks;
  return walletHooks.useIsActive();
};
export const useWalletIsActivating = (): boolean => {
  const walletHooks = useWalletHooks();
  return walletHooks.useIsActivating();
};

export function useWalletIsWrongNetwork(): boolean {
  const chainId = useWalletChainId();
  const appChainId = useAppChainId();
  const isWrongNetwork = useMemo(() => {
    if (chainId && appChainId) {
      return appChainId !== chainId;
    }
    return chainId !== getDefaultChainId();
  }, [appChainId, chainId]);
  return isWrongNetwork;
}
