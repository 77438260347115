import './index.less';

import { AbstractTooltipProps } from 'antd/lib/tooltip';
import BN from 'bignumber.js';
import { BigNumberish } from 'ethers';
import React, { FC, ReactNode, useCallback } from 'react';
import CountUp, { CountUpProps } from 'react-countup';

import { bNToS, formatDisplayNumber, formatPercentageString, toBN } from '@/utils/numberUtil';

import { Tooltip } from '../ToolTip';
interface IFormatNumberTooltipWrapProps {
  num: BigNumberish | BN;
  formattedNumStr?: string | undefined;
  colorShader?: boolean;
  isBold?: boolean;
  isShowTBMK?: boolean;
  toolTipProps?: AbstractTooltipProps;
  showToolTip?: boolean;
  prefix?: string;
  suffix?: string;
  colorSuffix?: boolean;
  isShowApproximatelyEqualTo?: boolean;
  countUpProps?: Omit<CountUpProps, 'end'>;
  showPositive?: boolean;
}

type DisplayStrWithCountUpTypes = {
  num: BN;
  iProps?: Omit<CountUpProps, 'end'>;
  formattedNumStr?: string;
  isShowTBMK?: boolean;
};
/**
 * TODO: temp for start
 * @param num
 * @returns
 */
function negativeNumberColor(num: BN): string {
  return toBN(num).gte(0) ? '#00BF80' : '#FF4D6A';
}

const DisplayStrWithCountUp: FC<DisplayStrWithCountUpTypes> = ({ num, iProps }) => {
  // get number decimals;

  const defaultProps = {
    duration: 1,
    useEasing: true,
    useGrouping: true,
    preserveValue: true,
    ...iProps,
    decimals: 9,
  } as CountUpProps;
  const formattingFn = useCallback((value: number | null) => {
    const formatNum = formatDisplayNumber({ num: value || 0, type: 'price' });
    return formatNum;
  }, []);

  return (
    <CountUp
      // redraw
      {...defaultProps}
      formattingFn={formattingFn}
      end={num?.toNumber()}
    />
  );
};
const formatNumberTooltipWrap: FC<IFormatNumberTooltipWrapProps> = function ({
  num,
  formattedNumStr,
  colorShader = false,
  isBold = false,
  isShowTBMK = false,
  toolTipProps,
  prefix,
  suffix,
  showToolTip = false,
  countUpProps,
  showPositive,
  colorSuffix = false,
  isShowApproximatelyEqualTo,
}) {
  const numRes = toBN(num || '');
  function displayStrWithCountUp(num: BN, disPlayStr: string) {
    return countUpProps && !num.isNaN() && !num.isZero() ? (
      <DisplayStrWithCountUp
        iProps={countUpProps}
        num={num}
        formattedNumStr={formattedNumStr}
        isShowTBMK={isShowTBMK}
      />
    ) : (
      disPlayStr
    );
  }

  const suffixDom = suffix && <span className="number-suffix">{suffix}</span>;

  function showColorNumber(colorShader: boolean, num: BN, disPlayStr: string, isBold = false): JSX.Element {
    if (isBold) {
      return colorShader ? (
        <span>
          <b className="font-number" style={{ color: negativeNumberColor(num) }}>
            {prefix}
            {displayStrWithCountUp(num, disPlayStr)}
          </b>
          {suffixDom}
        </span>
      ) : (
        <span>
          <b className="font-number">
            {prefix}
            {displayStrWithCountUp(num, disPlayStr)}
          </b>
          {suffixDom}
        </span>
      );
    }
    return colorShader ? (
      <span>
        <span className="font-number" style={{ color: negativeNumberColor(num) }}>
          {prefix}
          {displayStrWithCountUp(num, disPlayStr)}
          {colorSuffix && suffixDom}
        </span>
        {!colorSuffix && suffixDom}
      </span>
    ) : (
      <span>
        <span className="font-number">
          {prefix}
          {displayStrWithCountUp(num, disPlayStr)}
        </span>
        {suffixDom}
      </span>
    );
  }

  let displayNumStr = numRes.toString();
  if (formattedNumStr) {
    displayNumStr = formattedNumStr;
  } else {
    displayNumStr = formatDisplayNumber({ num: numRes, showPositive, isShowTBMK, isShowApproximatelyEqualTo });
  }

  if (prefix === '$') {
    if (displayNumStr.startsWith('-')) {
      prefix = '-$';
      displayNumStr = displayNumStr.slice(1);
    }
    if (displayNumStr.startsWith('+')) {
      prefix = '+$';
      displayNumStr = displayNumStr.slice(1);
    }
    if (displayNumStr.startsWith('＜')) {
      prefix = '＜$';
      displayNumStr = displayNumStr.slice(1);
    }
  }

  return showToolTip ? (
    <Tooltip {...toolTipProps} title={bNToS(numRes)}>
      {showColorNumber(colorShader, numRes, displayNumStr, isBold)}
    </Tooltip>
  ) : (
    showColorNumber(colorShader, numRes, displayNumStr, isBold)
  );
};

/**
 * formatNumberWithTooltip
 * @param num orgin number
 * @param showTooltip Whether tooltip is displayed
 * @param colorShader Whether to color negative numbers,
 * @returns
 */
export type FormatNumberWithTooltipType = Omit<IFormatNumberTooltipWrapProps, 'formattedNumStr'>;
export const formatNumberWithTooltip = ({
  num,
  colorShader = false,
  isBold = false,
  isShowTBMK = false,
  toolTipProps,
  prefix,
  suffix,
  showPositive,
  colorSuffix,
  countUpProps,
  isShowApproximatelyEqualTo,
}: FormatNumberWithTooltipType): React.ReactNode => {
  return formatNumberTooltipWrap({
    num,
    colorShader,
    isBold,
    toolTipProps,
    isShowTBMK,
    prefix,
    suffix,
    colorSuffix,
    countUpProps,
    showPositive,
    isShowApproximatelyEqualTo,
  });
};

/**
 * format price num display
 * @param num price
 * @param decimals default decimals
 * @param colorShader is show diff color
 * @param keepMaxOriginalNumber is keep original number
 * @returns
 */
export const formatPriceNumberWithTooltip = ({
  num,
  colorShader = false,
  isOperateNum = false,
  isShowTBMK = false,
  prefix,
  isShowApproximatelyEqualTo,
  suffix,
  showPositive,
}: {
  num: BigNumberish | BN;
  colorShader?: boolean;
  isBold?: boolean;
  toolTipProps?: AbstractTooltipProps;
  isOperateNum?: boolean;
  isShowTBMK?: boolean;
  prefix?: string;
  isShowApproximatelyEqualTo?: boolean;
  suffix?: string;
  showPositive?: boolean;
}): React.ReactNode => {
  const formattedNumStr = formatDisplayNumber({
    num,
    type: 'price',
    isOperateNum,
    isShowApproximatelyEqualTo,
    showPositive,
  });
  return formatNumberTooltipWrap({
    num,
    formattedNumStr,
    colorShader,
    isShowTBMK,
    prefix,
    suffix,
  });
};

export const formatStringWithTooltip = ({
  str,
  title,
  isBold = false,
  toolTipProps,
}: {
  str: string;
  title: string;
  isBold?: boolean;
  toolTipProps?: AbstractTooltipProps;
}): React.ReactNode => {
  return (
    <Tooltip {...toolTipProps} title={title}>
      {isBold ? <b className="font-number">{str}</b> : <span className="font-number">{str}</span>}
    </Tooltip>
  );
};

export function formatPercentage({
  percentage,
  hundredfold = true,
  colorShader = true,
  customTitle,
  decimals = 2,
}: {
  percentage: number | string;
  hundredfold?: boolean;
  colorShader?: boolean;
  customTitle?: ReactNode;
  decimals?: number;
}): ReactNode {
  const percentageNumber = Number(percentage) * (hundredfold ? 100 : 1);
  return (
    <Tooltip title={customTitle ? customTitle : undefined}>
      <span
        className="font-number"
        style={
          colorShader ? { color: percentageNumber && percentageNumber < 0 ? 'var(--short)' : 'var(--long)' } : undefined
        }>
        {' '}
        {formatPercentageString({ percentage, hundredfold, decimals })}
      </span>
    </Tooltip>
  );
}
