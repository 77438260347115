import './main.less';

// import { useHideMobileFooter } from '@/features/mobile/hooks';
// import WhiteListMask from '@/pages/components/WhitelistMask';
import { Layout as AntLayout } from 'antd';
import cls from 'classnames';
import React from 'react';

import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useIsPlainBgPage } from '@/hooks';

const { Content } = AntLayout;

export default function Main({ children }: { children: JSX.Element }): JSX.Element {
  const { deviceType } = useMediaQueryDevice();
  const isPlaiinPage = useIsPlainBgPage();
  // const hideFooter = useHideMobileFooter();
  return (
    <Content className={cls(`main-container ${deviceType}  ${false ? 'hide-footer' : ''}`)}>
      <div className={cls('main-body-wrap', isPlaiinPage && 'full-width')}>
        <div className="main-body">{children}</div>
      </div>
    </Content>
  );
}
