/**
 * @description Component-DepositFromWallet
 */
import './index.less';

import { QRCode } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { ReactComponent as ChainIcon } from '@/assets/svg/icon_modal_chain.svg';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo_color_dark.svg';
import { Button } from '@/components/Button';
import Copied from '@/components/Copied';
import Modal from '@/components/Modal';
import { useUserWalletInfo } from '@/features/user/hooks';
import { useCurrentToken } from '@/features/web3/hook';
import { useChainName } from '@/hooks/web3/useChain';
import ChainSelector from '@/pages/components/ChainSelector';
interface IPropTypes {
  className?: string;
}
const DepositFromWallet: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const userWallet = useUserWalletInfo();
  const token = useCurrentToken();
  const chainName = useChainName();
  return (
    <>
      <Button onClick={() => setOpen(true)} className="dapp-btn-sm blue" type="primary">
        {' '}
        {t('common.detail.wallet')}
      </Button>
      <Modal
        open={open}
        title={false}
        footer={false}
        closable={false}
        closeIcon={false}
        width={window.innerWidth}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        className="dapp-deposit-from-wallet full">
        <div className="dapp-deposit-from-wallet-header">
          <LogoIcon />
          <span className="title">{t('common.receivePage.walletTitle')}</span>
          <Button type="text" icon={<CloseIcon onClick={() => setOpen(false)} />} />
        </div>
        <div className="dapp-deposit-from-wallet-content">
          <div className="dapp-deposit-from-wallet-content-detail">
            <div className="dapp-deposit-from-wallet-content-detail-left">
              <div className="dapp-deposit-from-wallet-content-detail-left-title">
                {t('common.receivePage.walletDescTitle', { token })}
              </div>
              <div className="dapp-deposit-from-wallet-content-detail-left-subtitle">
                {t('common.receivePage.walletDescSubtitle', { token })}
              </div>
            </div>
            <div className="dapp-deposit-from-wallet-content-detail-right">
              <div className="dapp-deposit-from-wallet-content-detail-right-title">
                <ChainIcon /> {t('common.receivePage.bankDetailTitle')}
              </div>
              <div className="dapp-deposit-from-wallet-content-detail-right-container">
                <ChainSelector disabled={true} />
                <div className="dapp-deposit-from-wallet-content-detail-right-wallet">
                  <div className="dapp-deposit-from-wallet-content-detail-right-wallet-left">
                    <div className="dapp-deposit-from-wallet-content-detail-right-wallet-left-title">
                      <div className="top"> {t('common.receivePage.walletAddrTitle')}</div>
                      <div className="bottom">{t('common.receivePage.walletAddrSubtitle', { token, chainName })}</div>
                    </div>
                    <div className="dapp-deposit-from-wallet-content-detail-right-wallet-left-address">
                      {userWallet?.address}
                      {userWallet && <Copied copyValue={userWallet?.address} />}
                    </div>
                  </div>
                  {userWallet && <QRCode value={userWallet?.address} size={80} includeMargin={false} />}
                </div>
                <div className="dapp-deposit-from-wallet-content-detail-right-message">
                  <div className="dapp-deposit-from-wallet-content-detail-right-message-title">
                    {t('common.important')}
                  </div>
                  <p>{t('common.receivePage.walletMsg1', { token })}</p>
                  <p>{t('common.receivePage.walletMsg2')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DepositFromWallet;
