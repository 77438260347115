import { CHAIN_ID } from '@derivation-tech/web3-core';
import { AddEthereumChainParameter } from '@web3-react/types';
import { Overrides } from 'ethers';

import { chainConfigs, DEFAULT_CHAIN_ID, NETWORK_LABEL, SUPPORTED_CHAIN_ID } from '@/constants/chain';
import { CONNECTOR_RPC_URLS } from '@/constants/rpc';
import { APP_CHAIN_ID } from '@/constants/storage';
/**
 * get chainName by Chain id
 * @param chainId
 * @returnschain
 */
export function getChainName(chainId: CHAIN_ID): string {
  let chainName = '';
  if (NETWORK_LABEL[chainId]) {
    chainName = NETWORK_LABEL[chainId] || '';
  }

  return chainName;
}

function isExtendedChainInformation(chainId: number, hasEthTestnet: boolean): boolean {
  if (hasEthTestnet) return ![CHAIN_ID.ETHEREUM, CHAIN_ID.GOERLI, CHAIN_ID.RINKEBY, CHAIN_ID.ROPSTEN].includes(chainId);
  return ![CHAIN_ID.ETHEREUM].includes(chainId);
}

export function getAddChainParameters(chainId: number, hasEthTestnet: boolean): AddEthereumChainParameter | number {
  const chainConfig = chainConfigs[chainId];
  console.log('🚀 ~ file: chain.tsx:29 ~ getAddChainParameters ~ chainConfig:', chainConfig);
  if (isExtendedChainInformation(chainId, hasEthTestnet)) {
    return {
      chainId: chainConfig.chainId,
      chainName: chainConfig.chainName,
      nativeCurrency: {
        ...chainConfig.nativeToken,
        name: chainConfig.nativeToken.name || 'ETH',
        decimals: 18,
      },
      rpcUrls: CONNECTOR_RPC_URLS[chainConfig.chainId],
      blockExplorerUrls: [chainConfig.explorer],
    };
  } else {
    return chainId;
  }
}

export function getGasSettings(): Overrides {
  return { gasLimit: 2500000 };
}

/**
 * get default chainId from localStorage or default config
 * @returns
 */
export function getDefaultChainId(): number {
  const localChainId = localStorage.getItem(APP_CHAIN_ID);
  if (localChainId !== null) {
    if (SUPPORTED_CHAIN_ID.includes(Number(localChainId))) {
      return Number(localChainId);
    }
  }
  return DEFAULT_CHAIN_ID;
}

export function isTestnet(chainId: number | undefined): boolean {
  return chainId ? [CHAIN_ID.GOERLI, CHAIN_ID.MUMBAI].includes(chainId) : false;
}
