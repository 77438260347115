import { useMemo } from 'react';

import { SUPPORTED_WALLETS, WALLET_CONNECT_STATUS, WalletType } from '@/constants/wallets';
import { useAppSelector } from '@/hooks';
import { IWalletInfo } from '@/types/wallet';

import { selectWalletConnectStatus, selectWalletType } from './walletSlice';

export function useWalletConfigList(): IWalletInfo[] {
  return useMemo(() => {
    return Object.values(SUPPORTED_WALLETS);
  }, []);
}

/**
 * get wallet config
 * @param walletType
 * @returns
 */
export function useWalletConfig(walletType: WalletType): IWalletInfo {
  return useMemo(() => {
    return SUPPORTED_WALLETS[walletType];
  }, [walletType]);
}

export function useWalletConnectStatus(): WALLET_CONNECT_STATUS {
  const connectStatus = useAppSelector(selectWalletConnectStatus);
  return connectStatus;
}

export function useWalletType(): WalletType {
  const walletType = useAppSelector(selectWalletType);
  return walletType;
}
