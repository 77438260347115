/**
 * @description Component-Transactions
 */
import './index.less';

import { Empty } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyIcon } from '@/assets/svg/icon_empty_96.svg';
import { ITxEventTransformed } from '@/types/tx';

import Transaction from './Transaction';
interface IPropTypes {
  className?: string;
  txs: ITxEventTransformed[];
}
const Transactions: FC<IPropTypes> = function ({ txs }) {
  const { t } = useTranslation();
  return (
    <div className="dapp-transactions">
      <div className="dapp-transactions-title">{t('common.transaction.title')}</div>
      <div className="dapp-transactions-table">
        {txs.length ? (
          txs.map((tx) => <Transaction tx={tx} key={tx.txHash + tx.logIndex} />)
        ) : (
          <Empty
            image={<EmptyIcon />}
            description={t('common.transaction.emptyLine')}
            className="dapp-transactions-table-empty"
          />
        )}
      </div>
    </div>
  );
};

export default Transactions;
