/**
 * @description Component-Transaction
 */
import './index.less';

import { Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronIcon } from '@/assets/svg/icon_chevron_down.svg';
import { ReactComponent as LinkIcon } from '@/assets/svg/icon_open_12.svg';
import { NETWORK_LABEL } from '@/constants/chain';
import { ADDRESS_INFO, BANK_INFO } from '@/constants/global';
import { TOKENS, TOKENS_CURRENCY, TOKENS_ICON_MAP } from '@/constants/token';
import { useUserWalletInfo } from '@/features/user/hooks';
import { useChainId, useEtherscanLink } from '@/hooks/web3/useChain';
import AddressInfo from '@/pages/components/AddressInfo';
import BankInfo from '@/pages/components/BankInfo';
import { ITxEventTransformed, TxSide, TxTarget } from '@/types/tx';
import { shortenAddress } from '@/utils/address';
import { formatNumber } from '@/utils/numberUtil';
interface IPropTypes {
  className?: string;
  tx: ITxEventTransformed;
}
const Transaction: FC<IPropTypes> = function ({ tx }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const contentStyles = useSpring({
    display: open ? 'flex' : 'none',
  });
  const chainId = useChainId();
  const walletInfo = useUserWalletInfo();
  const getEtherScanLink = useEtherscanLink();
  const targetAddress = useMemo(() => {
    if (tx.side === TxSide.DEPOSIT) {
      return tx.from;
    }
    return tx.to;
  }, [tx]);
  return (
    <div className={classNames('dapp-transaction', open ? 'open' : '')}>
      <div onClick={() => setOpen(!open)} className="dapp-transaction-header">
        <div className="dapp-transaction-header-left">
          {' '}
          <img
            className="dapp-transaction-header-left-icon"
            width={24}
            height={24}
            src={TOKENS_ICON_MAP[tx.symbol as TOKENS] || ''}
          />
          <div className="dapp-transaction-header-left-day">{moment(tx.timestamp).format('DD MMM HH:mm')}</div>
          <div className="dapp-transaction-header-left-line">
            {tx.side === TxSide.DEPOSIT ? t('common.transaction.depositLine') : t('common.transaction.withdrawLine')}{' '}
            {tx.target === TxTarget.BANK ? t('common.transaction.bankLine') : t('common.transaction.walletLine')}
          </div>
          <div className="dapp-transaction-header-left-info">
            {tx.target === TxTarget.BANK ? (
              <>
                <span>{BANK_INFO.accountName}</span>/<span>{BANK_INFO.bankNameShort}</span>/
                <span>{BANK_INFO.accountNumber}</span>
              </>
            ) : (
              <>
                {chainId && <span>{NETWORK_LABEL[chainId]} </span>}/
                <Tooltip title={targetAddress}>
                  <span>{shortenAddress(targetAddress || ADDRESS_INFO.address)}</span>
                </Tooltip>
              </>
            )}
            <span></span>
          </div>
        </div>
        <div className="dapp-transaction-header-right">
          <span>
            <span className={tx.side}>
              {TxSide.DEPOSIT === tx.side ? '+' : '-'}
              {formatNumber(tx.amount, 2)}
            </span>{' '}
            {tx.symbol}
          </span>

          <ChevronIcon className={open ? 'rotate' : ''} />
        </div>
      </div>
      <animated.div style={contentStyles} className="dapp-transaction-content">
        <div className="dapp-transaction-content-sides">
          <div className="dapp-transaction-content-sides-side">
            <div className="dapp-transaction-content-sides-side-header">
              <span> {t('common.transaction.sent')}</span>
              <span>
                {formatNumber(tx.amount, 2)}{' '}
                {tx.target === TxTarget.BANK && tx.side === TxSide.DEPOSIT
                  ? TOKENS_CURRENCY[tx.symbol as TOKENS]
                  : tx.symbol}
              </span>
            </div>
            <div className="dapp-transaction-content-sides-side-content">
              <div> {t('common.transaction.sender')}</div>
              {tx.side === TxSide.DEPOSIT ? (
                tx.target === TxTarget.BANK ? (
                  <BankInfo />
                ) : (
                  <AddressInfo
                    accountName={ADDRESS_INFO.addressName}
                    chainId={chainId}
                    address={tx.from || ADDRESS_INFO.address}
                  />
                )
              ) : (
                <AddressInfo
                  chainId={chainId}
                  address={walletInfo?.address || ''}
                  accountName={walletInfo?.username || ''}
                />
              )}
            </div>
          </div>
          <div className="dapp-transaction-content-sides-side">
            <div className="dapp-transaction-content-sides-side-header">
              <span> {t('common.transaction.receive')}</span>
              <span>
                {formatNumber(tx.amount, 2)}{' '}
                {tx.target === TxTarget.BANK && tx.side === TxSide.SEND
                  ? TOKENS_CURRENCY[tx.symbol as TOKENS]
                  : tx.symbol}
              </span>
            </div>
            <div className="dapp-transaction-content-sides-side-content">
              <div> {t('common.transaction.receiver')}</div>
              {tx.side === TxSide.DEPOSIT ? (
                <AddressInfo
                  chainId={chainId}
                  address={walletInfo?.address || ''}
                  accountName={walletInfo?.username || ''}
                />
              ) : tx.target === TxTarget.BANK ? (
                <BankInfo />
              ) : (
                <AddressInfo chainId={chainId} address={tx.to || ADDRESS_INFO.address} />
              )}
            </div>
          </div>
        </div>
        <div className="dapp-transaction-content-footer">
          <div className="dapp-transaction-content-footer-timestamp">
            {moment(tx.timestamp).format('DD MMM YYYY HH:mm:ss')}
          </div>
          <div className="dapp-transaction-content-footer-hash">
            {t('common.transaction.hashTitle')}
            <a target="_blank" href={getEtherScanLink(tx.txHash, 'transaction')} rel="noreferrer">
              <span>{shortenAddress(tx.txHash)}</span>
              <LinkIcon />
            </a>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default Transaction;
