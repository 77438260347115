import React from 'react';

import BalanceGlobalEffect from '@/features/balance/GlobalEffect';
import ChainGlobalEffect from '@/features/chain/GlobalEffect';
import GlobalGlobalEffect from '@/features/global/GlobalEffect';
import UserGlobalEffect from '@/features/user/GlobalEffect';
import WalletGlobalEffect from '@/features/wallet/GlobalEffect';
import Web3GlobalEffect from '@/features/web3/GlobalEffect';

function GlobalEffect(): JSX.Element {
  return (
    <>
      <Web3GlobalEffect />
      <ChainGlobalEffect />
      <GlobalGlobalEffect />
      <BalanceGlobalEffect />
      <WalletGlobalEffect />
      <UserGlobalEffect />
    </>
  );
}

export default React.memo(GlobalEffect);
