import { configureStore } from '@reduxjs/toolkit';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
// import { save, load } from 'redux-localstorage-simple';
import { Provider as ReduxProvider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CloseButton, IconLoading, IconNotificationError, IconNotificationSuccess } from '@/components/Notification';
import ActiveConnectorManager from '@/connectors/ActiveConnectorManager';

// import ActiveConnectorManager from '@/connectors/ActiveConnectorManager';
import { connectors } from './connectors/connectors';
import { chainConfigMiddleware } from './features/middleware';
import { store, storeReducer } from './features/store';
import AntDConfigProvider from './layout/AntDConfigProvider';

export function StoreProviderWrapper({
  children,
  reduxStore = store,
}: {
  children: JSX.Element;
  reduxStore?: typeof store;
}): JSX.Element {
  reduxStore = { ...store, ...reduxStore };
  return (
    <ReduxProvider store={reduxStore}>
      <AntDConfigProvider>
        <Web3ReactProvider connectors={connectors}>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="light"
            closeButton={CloseButton}
            icon={({ type }) => {
              if (type === 'success') return <IconNotificationSuccess />;
              else if (type === 'error') return <IconNotificationError />;
              else if (type === 'info') return <IconLoading />; //<IconNotificationInfo />;
              return <IconLoading />;
            }}
          />

          <ActiveConnectorManager>
            <HashRouter>{children}</HashRouter>
          </ActiveConnectorManager>
        </Web3ReactProvider>
      </AntDConfigProvider>
    </ReduxProvider>
  );
}

export default function ProviderWrapper({
  children,
  reducer = storeReducer,
}: {
  children: JSX.Element;
  reducer?: Partial<typeof storeReducer>;
}): JSX.Element {
  const sReducer = { ...storeReducer, ...reducer };
  const store = configureStore({
    reducer: sReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(chainConfigMiddleware),
    //     .concat(save({ states: PERSISTED_KEYS })),
    // preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
  });
  return StoreProviderWrapper({ children, reduxStore: store });
}

export function getProviderWrapper(reducer?: Partial<typeof storeReducer>) {
  // eslint-disable-next-line react/display-name, @typescript-eslint/explicit-module-boundary-types
  return ({ children }: { children: JSX.Element }) => <ProviderWrapper reducer={reducer}>{children}</ProviderWrapper>;
}
