/**
 * @description Component-WalletRecipientSelector
 */
import './index.less';

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '@/components/Input';
import { ADDRESS_INFO } from '@/constants/global';

import ChainSelector from '../ChainSelector';
// import { Option, Select } from '../Select';
interface IPropTypes {
  className?: string;
  onChange: (address: string) => void;
  value?: string;
}
const WalletRecipientSelector: FC<IPropTypes> = function ({ onChange, value }) {
  const { t } = useTranslation();
  const [address, setAddress] = useState(ADDRESS_INFO.address);
  useEffect(() => {
    value && setAddress(value);
  }, [value]);
  return (
    <div className="dapp-wallet-recipient-selector">
      {' '}
      <div className="dapp-wallet-recipient-selector-section">
        <div className="dapp-wallet-recipient-selector-title">{t('common.recSelector.walletTitle')}</div>
        <div className="dapp-wallet-recipient-selector-select">
          <div className="dapp-wallet-recipient-selector-select-label">{t('common.recSelector.chainLabel')}</div>
          <ChainSelector />
        </div>
      </div>
      <div className="dapp-wallet-recipient-selector-section">
        <div className="dapp-wallet-recipient-selector-select">
          <div className="dapp-wallet-recipient-selector-select-label">{t('common.recSelector.walletLabel')}</div>
          {/* <Select onChange={onChange} defaultValue={ADDRESS_INFO.address}>
            <Option value={ADDRESS_INFO.address}>
              <div className="select-selected">
                {ADDRESS_INFO.addressName}/{shortenAddress(ADDRESS_INFO.address)}
              </div>
              <div className="select-left">{ADDRESS_INFO.addressName}</div>
              <div className="select-right">
                <div className="select-right-top">{shortenAddress(ADDRESS_INFO.address)}</div>
              </div>
            </Option>
          </Select> */}
          <Input
            value={address}
            placeholder={t('common.sendPage.inputPlaceHolder')}
            onChange={(e) => {
              const value = e.target.value;
              onChange(value);
              setAddress(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WalletRecipientSelector;
