import './index.less';

import { App, Layout as AntLayout } from 'antd';
import React, { FC } from 'react';

import Main from './main';

interface IProps {
  children?: React.ReactNode;
}

const LayoutComponent: FC<IProps> = function ({ children }) {
  return (
    <App>
      <AntLayout className={`app-layout`}>
        <Main>
          <div className="layout_container">{children}</div>
        </Main>
      </AntLayout>
    </App>
  );
};

export default LayoutComponent;
