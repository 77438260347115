// import * as Comlink from 'comlink';
import { CHAIN_ID } from '@derivation-tech/web3-core';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import localforage from 'localforage';

import { FETCHING_STATUS } from '@/constants';
import { CREATED_WALLET } from '@/constants/storage';
import { IBankInfo, IUserWallet } from '@/types/user';

export const setUserWalletInfo = createAction<IUserWallet>('user/setWalletInfo');
export const setUserTxStatus = createAction<FETCHING_STATUS>('user/setUserTxStatus');

export const sendToWallet = createAsyncThunk(
  'user/sendToWallet',
  async ({
    chainId,
    contractAddr,
    to,
    username,
    amount,
  }: {
    chainId: CHAIN_ID;
    contractAddr: string;
    to: string;
    amount: number;
    username: string;
  }): Promise<string | undefined> => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_ROOT}send-to-wallet`, {
        chainId,
        contractAddr,
        to,
        amount,
        username,
      });
      if (data) {
        console.log('🚀 ~ file: actions.ts:34 ~ res:', data);
        return data;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  },
);

export const login = createAsyncThunk(
  'user/login',
  async ({ username }: { password: string; username: string }, {}): Promise<IUserWallet | undefined> => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_ROOT}login`, {
        username,
        password: 'DefaultP@ssw0rd!',
      });
      if (data) {
        await localforage.setItem(CREATED_WALLET, data);
        return data;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  },
);
export const receiveFromBank = createAsyncThunk(
  'user/receiveFromBank',
  async ({
    chainId,
    contractAddr,
    to,
    amount,
    username,
  }: {
    chainId: CHAIN_ID;
    contractAddr: string;
    to: IBankInfo;
    username: string;
    amount: number;
  }): Promise<string | undefined> => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_ROOT}receive-from-bank`, {
        chainId,
        contractAddr,
        to,
        username,
        amount,
      });
      if (data) {
        console.log('🚀 ~ file: actions.ts:34 ~ res:', data);
        return data;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  },
);
export const sendToBank = createAsyncThunk(
  'user/sendToBank',
  async ({
    chainId,
    contractAddr,
    to,
    amount,
    username,
  }: {
    chainId: CHAIN_ID;
    contractAddr: string;
    to: IBankInfo;
    username: string;
    amount: number;
  }): Promise<string | undefined> => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_ROOT}send-to-bank`, {
        chainId,
        contractAddr,
        to,
        username,
        amount,
      });
      if (data) {
        console.log('🚀 ~ file: actions.ts:34 ~ res:', data);
        return data;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  },
);
