import { CHAIN_ID } from '@derivation-tech/web3-core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { THEME_ENUM } from '@/constants';
import { LOCAL_THEME } from '@/constants/storage';
import { resetHistory } from '@/features/graph/actions';
import { useAppDispatch } from '@/hooks';
import { useChainId, useUserAddr } from '@/hooks/web3/useChain';

import { setTheme } from '../actions';

function useSetDefaultConfig(chainId: CHAIN_ID | undefined) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const localTheme = localStorage.getItem(LOCAL_THEME);
    if (localTheme) {
      dispatch(setTheme(localTheme as THEME_ENUM));
    }
  }, [dispatch, chainId]);
}

function useResetStateGlobal() {
  const dispatch = useAppDispatch();
  const chainId = useChainId();
  const userAddr = useUserAddr();
  const { pathname } = useLocation();
  useEffect(() => {
    if (chainId) {
      dispatch(resetHistory({ chainId }));
    }
  }, [chainId, userAddr, dispatch, pathname]);
}

export default function GlobalGlobalEffect(): null {
  const chainId = useChainId();
  useSetDefaultConfig(chainId);
  useResetStateGlobal();
  return null;
}
