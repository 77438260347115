/**
 * @description Logo
 */
import React, { FC } from 'react';

import { ReactComponent as LogoIcon } from './assets/logo_white.svg';
const Logo: FC = function () {
  return <> {<LogoIcon />}</>;
};

export default Logo;
