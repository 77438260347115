/**
 * @description Component-DepositFromBank
 */
import './index.less';

import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronRight } from '@/assets/svg/icon_chevron_right.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { ReactComponent as BankIcon } from '@/assets/svg/icon_modal_bank.svg';
import { ReactComponent as DoneIcon } from '@/assets/svg/icon_ok_round.svg';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo_color_dark.svg';
import { Button } from '@/components/Button';
import Copied from '@/components/Copied';
import Modal from '@/components/Modal';
import { FETCHING_STATUS } from '@/constants';
import { BANK_INFO } from '@/constants/global';
import { TOKENS, TOKENS_CURRENCY } from '@/constants/token';
import { receiveFromBank, setUserTxStatus } from '@/features/user/actions';
import { useUserTxStatus, useUserWalletInfo } from '@/features/user/hooks';
import { useContract, useCurrentToken } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
interface IPropTypes {
  className?: string;
}
const DepositFromBank: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const walletInfo = useUserWalletInfo();
  const chainId = useChainId();
  const currentToken = useCurrentToken();
  const contract = useContract(currentToken);
  const txStatus = useUserTxStatus();
  const transfer = useCallback(() => {
    chainId &&
      open &&
      walletInfo &&
      contract &&
      dispatch(
        receiveFromBank({
          chainId,
          to: BANK_INFO,
          contractAddr: contract?.address,
          amount: 1000,
          username: walletInfo?.username,
        }),
      );
  }, [chainId, contract, dispatch, walletInfo, open]);
  return (
    <>
      {' '}
      <Button
        onClick={() => {
          dispatch(setUserTxStatus(FETCHING_STATUS.INIT));
          setOpen(true);
        }}
        className="dapp-btn-sm blue"
        type="primary">
        {' '}
        {t('common.detail.bank')}
      </Button>
      <Modal
        open={open}
        title={false}
        footer={false}
        closable={false}
        closeIcon={false}
        width={window.innerWidth}
        onCancel={() => txStatus !== FETCHING_STATUS.FETCHING && setOpen(false)}
        onOk={() => txStatus !== FETCHING_STATUS.FETCHING && setOpen(false)}
        className="dapp-deposit-from-bank full">
        <div className="dapp-deposit-from-bank-header">
          <LogoIcon />
          <span className="title">{t('common.receivePage.bankTitle')}</span>
          <Button type="text" icon={<CloseIcon onClick={() => setOpen(false)} />} />
        </div>
        <div className="dapp-deposit-from-bank-content">
          <div className="dapp-deposit-from-bank-content-detail">
            <div className="dapp-deposit-from-bank-content-detail-left">
              <div className="dapp-deposit-from-bank-content-detail-left-title">
                {t('common.receivePage.bankDescTitle')}
              </div>
              <div className="dapp-deposit-from-bank-content-detail-left-subtitle">
                {t('common.receivePage.bankDescSubtitle', {
                  token: currentToken,
                })}
              </div>
            </div>
            <div className="dapp-deposit-from-bank-content-detail-right">
              <div className="dapp-deposit-from-bank-content-detail-right-title">
                <BankIcon /> {t('common.receivePage.bankDetailTitle')}
              </div>
              <dl>
                <dt>{t('common.receivePage.bankDetailCurrency')}</dt>
                <dd>
                  {TOKENS_CURRENCY[currentToken as TOKENS]}
                  <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailBName')}</dt>
                <dd>
                  DIGITAL HONG KONG DOLLAR{' '}
                  <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailBAddr')}</dt>
                <dd>
                  100 VIC&apos;S ROAD, CENTRAL AND WESTERN DISTRICT{' '}
                  <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailBAN')}</dt>
                <dd>
                  099-001-12345678{' '}
                  <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailBankN')}</dt>
                <dd>
                  HONG KONG DIGITAL BANK{' '}
                  <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailRouting')}</dt>
                <dd>
                  000123456 <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
              <dl>
                <dt>{t('common.receivePage.bankDetailSwift')}</dt>
                <dd>
                  HKDBHKZHICA <Copied title={t('common.copy')} copiedTitle={t('common.copied')} copyValue={'HKD'} />{' '}
                </dd>
              </dl>
            </div>
          </div>
          <div className="dapp-deposit-from-bank-content-footer">
            {txStatus === FETCHING_STATUS.FETCHING && (
              <Button type="text" loading={true}>
                {t('common.processing')}
              </Button>
            )}
            {txStatus === FETCHING_STATUS.INIT && (
              <Button onClick={transfer} type="text">
                {t('common.receivePage.depositFromBankBottomLine')} <ChevronRight />
              </Button>
            )}
            {txStatus === FETCHING_STATUS.DONE && (
              <>
                <DoneIcon />
                {t('common.done')}
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DepositFromBank;
