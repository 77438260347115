import { CHAIN_ID } from '@derivation-tech/web3-core';
import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import { DEFAULT_EMPTY_ACCOUNT } from '@/constants/global';
import { setCurrentUserAddr } from '@/features/user/userSlice';
import { useAppDispatch } from '@/hooks';
import { useWalletAccount } from '@/hooks/web3/useWalletNetwork';
import { useAppChainId } from '@/hooks/web3/useWeb3Network';

import { changeCurrentChainId } from '../actions';

export const useListenChainNetworkChange = (chainId: number | undefined): [CHAIN_ID | undefined] => {
  const dispatch = useAppDispatch();

  const changeAccount = useCallback(
    (account: string) => {
      if (account) {
        dispatch(setCurrentUserAddr({ account: account.toLowerCase() }));
      }
      account && ReactGA.set({ userId: account.toLowerCase() });
    },
    [dispatch],
  );
  const account = useWalletAccount();
  const [result, setResult] = useState(chainId);

  const onAfterChainIdChange = useCallback(
    (chainId: number) => {
      dispatch(changeCurrentChainId({ chainId }));
      window.__gasPrice = 0;
    },
    [dispatch],
  );

  /*
   *this effect fires at the initiation of the app.
   * */
  useEffect(() => {
    if (!chainId) {
      return;
    }

    onAfterChainIdChange(chainId);
    setResult(chainId);
  }, [chainId, dispatch, onAfterChainIdChange]);

  useEffect(() => {
    const accountAddr = account || DEFAULT_EMPTY_ACCOUNT;
    if (accountAddr) {
      changeAccount(accountAddr);
    }
  }, [account, changeAccount]);

  return [result];
};

/**
 * watch chainId change to update reducer chainId and fetch chainConfig
 * @returns
 */
export function useChainIdWatcher(chainId: number | undefined): null {
  useListenChainNetworkChange(chainId);
  return null;
}

export default function ChainGlobalEffect(): null {
  const chainId = useAppChainId();
  useChainIdWatcher(chainId);
  return null;
}
