import { createSlice } from '@reduxjs/toolkit';

import { SystemPrefersColorScheme, THEME_ENUM } from '@/constants';
import { LOCAL_THEME } from '@/constants/storage';

import { AppState } from '../store';
import { setTheme } from './actions';
export type WalletModalType = 'Account' | 'Wallet';

const localTheme = (localStorage?.getItem(LOCAL_THEME) as THEME_ENUM) || 'auto';

export interface IGlobalState {
  dataTheme: SystemPrefersColorScheme;
}
const initialState: IGlobalState = {
  dataTheme: localTheme,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTheme, (state, { payload }) => {
      state.dataTheme = payload;
    });
  },
});

export const selectGlobalState = (state: AppState): IGlobalState => state.global;

export const selectDataTheme = (state: AppState): SystemPrefersColorScheme => state.global.dataTheme;

export default globalSlice.reducer;
