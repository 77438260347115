import type { WebSocketProvider } from '@ethersproject/providers';
import type { Actions } from '@web3-react/types';
import { Connector } from '@web3-react/types';

// import type { ReconnectingWebSocketProvider } from './ReconnectingWebSocketProvider';
import { getBestProvider } from './utils';

/**
 * @param urlMap - A mapping from chainIds to RPC urls.
 * @param defaultChainId - The chainId to connect to in activate if one is not provided.
 * @param timeout - Timeout, in milliseconds, after which to treat network calls to urls as failed when selecting
 * online providers.
 */
export interface IWebSocketNetworkConstructorArgs {
  actions: Actions;
  urlMap: { [chainId: number]: string | string[] };
  defaultChainId?: number;
  timeout?: number;
}

export class WebSocketConnector extends Connector {
  /** {@inheritdoc Connector.provider} */
  public readonly provider: undefined;
  /** {@inheritdoc Connector.customProvider} */
  public customProvider?: WebSocketProvider;

  private readonly providerCache: Record<number, Promise<WebSocketProvider> | undefined> = {};

  private readonly urlMap: Record<number, string[]>;
  private readonly defaultChainId: number;
  private readonly timeout: number;

  constructor({
    actions,
    urlMap,
    defaultChainId = Number(Object.keys(urlMap)[0]),
    timeout = 5000,
  }: IWebSocketNetworkConstructorArgs) {
    super(actions);

    this.urlMap = Object.keys(urlMap).reduce<Record<number, string[]>>((accumulator, chainId) => {
      const urls = urlMap[Number(chainId)];

      if (Array.isArray(urls)) {
        accumulator[Number(chainId)] = urls;
      } else {
        accumulator[Number(chainId)] = [urls];
      }

      return accumulator;
    }, {});
    this.defaultChainId = defaultChainId;
    this.timeout = timeout;
  }

  private async isomorphicInitialize(chainId: number): Promise<WebSocketProvider> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.providerCache[chainId]) return this.providerCache[chainId]!;

    const urls = this.urlMap[chainId];

    return (this.providerCache[chainId] = import('./ReconnectingWebSocketProvider').then(
      ({ ReconnectingWebSocketProvider }) => {
        if (urls.length === 1) {
          return (this.providerCache[chainId] = Promise.resolve(
            ReconnectingWebSocketProvider.getInstance(chainId, urls[0]),
          ));
        } else {
          const providers = urls.map((url) => ReconnectingWebSocketProvider.getInstance(chainId, url));
          return getBestProvider(providers, this.timeout);
        }
      },
    ));
  }

  /**
   * Initiates a connection.
   *
   * @param desiredChainId - The desired chain to connect to.
   */
  public async activate(desiredChainId = this.defaultChainId): Promise<void> {
    let cancelActivation: () => void;
    if (!this.providerCache[desiredChainId]) {
      cancelActivation = this.actions.startActivation();
    }

    return this.isomorphicInitialize(desiredChainId)
      .then(async (customProvider) => {
        this.customProvider = customProvider;
        const { chainId } = await this.customProvider.getNetwork();
        this.actions.update({ chainId, accounts: [] });
      })
      .catch((error: Error) => {
        cancelActivation?.();
        throw error;
      });
  }
}
