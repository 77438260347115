import '@/utils/log';

import { notification } from 'antd';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import VConsole from 'vconsole';

import { registerWorkers } from '@/worker/factory';

import { initReactGA } from './utils/analytics';

initReactGA();

registerWorkers();

notification.config({
  duration: 9,
  placement: 'topRight',
  top: 70,
});

// bigNumber decimal config
BigNumber.config({ DECIMAL_PLACES: 18 });

dayjs.extend(utc);
dayjs.extend(duration);

if (process.env.REACT_APP_APP_ENABLE_VCONSOLE === 'true') {
  new VConsole();
}
