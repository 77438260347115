import { WebSocketProvider } from '@ethersproject/providers';

import { appConnectorHooks } from '../../connectors/appConnector';

const { useProvider, useChainId, useAccount, useIsActive, useIsActivating } = appConnectorHooks;
export const useAppProvider = useProvider;
export const useAppChainId = useChainId;
export const useAppAccount = useAccount;
export const useAppIsActive = useIsActive;
export const useAppIsActivating = useIsActivating;

export const useWebSocketProvider = (): WebSocketProvider | undefined => {
  return useAppProvider();
};
