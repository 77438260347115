import './index.less';

import { SearchOutlined } from '@ant-design/icons';
import { Input as AntDInput, InputProps, InputRef } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

// import { ReactComponent as IconClear } from './assets/icon_clear_field.svg';

export default function Input(props: InputProps): JSX.Element {
  const inputRef = useRef<InputRef>(null);
  const [position, setPosition] = useState(0);
  useEffect(() => {
    if (inputRef.current?.input) {
      // fix cursor position issue
      inputRef.current.input.selectionStart = position;
      inputRef.current.input.selectionEnd = position;
    }
  }, [position, props.value]);
  return (
    <AntDInput
      // {...props}
      {..._.omit(props, ['type'])}
      ref={inputRef}
      onChange={(e) => {
        const currentPosition = e.currentTarget?.selectionStart || 0;
        setPosition(currentPosition);

        props?.onChange && props?.onChange(e);
      }}
      className={classNames('dapp-input', props.className)}></AntDInput>
  );
}

export function Search(props: InputProps): JSX.Element {
  return <Input prefix={<SearchOutlined />} {...props} className={classNames('dapp-search', props.className)}></Input>;
}
export const { Password } = AntDInput;
