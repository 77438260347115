/**
 * @description Page-Footer
 */
import React, { FC, memo, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@/components/Container';
import { Desktop, Mobile } from '@/components/MediaQuery';
import { Tooltip } from '@/components/ToolTip';
import { useGa } from '@/hooks/useGa';
import { GaCategory } from '@/utils/analytics';

// import MobileFooter from '@/pages/mobile/components/MobileFooter';
import styles from './index.m.less';

const Footer: FC = function () {
  const { t } = useTranslation();
  const gaEvent = useGa();
  const rightLis = useMemo(() => {
    return [
      {
        label: t('common.rights.pp'),
        href: '',
        action: 'Term-Click on Privacy Policy',
      },
      {
        label: t('common.rights.tos'),
        href: '',
        action: 'Term-Click on Terms of Use',
      },
      {
        label: t('common.rights.cookieN'),
        href: '',
        action: 'Term-Click on Cookies Notice',
      },
    ].map((v, i) => {
      return (
        <li key={i}>
          <Tooltip title={t('common.comingSoon')}>
            <a
              onClick={(e) => {
                e.preventDefault();
                gaEvent({
                  category: GaCategory.FOOTER,
                  action: v.action,
                });
              }}
              href={v.href}>
              {v.label}
            </a>
          </Tooltip>
        </li>
      );
    });
  }, [t, gaEvent]);

  return (
    <Suspense>
      <Desktop>
        <Container className={styles['footer-wrap']}>
          <div className={styles['footer']}>
            <span>{t('common.rights.copyRightLine')}</span>
            <ul>{rightLis}</ul>
          </div>
        </Container>
      </Desktop>
      <Mobile>
        <></>
        {/* <MobileFooter /> */}
      </Mobile>
    </Suspense>
  );
};

export default memo(Footer);
