/**
 * @description Component-Button
 */
import './index.less';

import { Button as AntButton } from 'antd';
import classnames from 'classnames';
import React, { FC, useMemo } from 'react';

import { ReactComponent as IconLoading } from '@/assets/svg/icon_loading.svg';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { IButtonProps } from '@/types/button';

type PropMapTypes = {
  [propName: string]: string;
};

const Button: FC<IButtonProps> = function ({ className, size, ...props }) {
  const { deviceType } = useMediaQueryDevice();

  const btnClassName = useMemo(() => {
    if (props.type === undefined) return 'dapp-btn-default';
    const typeMap: PropMapTypes = {
      primary: 'primary',
      ghost: 'ghost',
      link: 'link',
      dashed: 'radio',
      text: 'text',
    };
    return `dapp-btn-${typeMap[props.type]}`;
  }, [props.type]);

  const icon = useMemo(() => {
    if (props.loading) {
      return (
        <span className="dapp-btn-loading-icon dapp-btn-icon">
          <IconLoading />
        </span>
      );
    }

    return props.icon;
  }, [props.icon, props.loading]);

  return (
    <AntButton
      data-testid="dapp-btn"
      className={classnames(
        'dapp-btn',
        btnClassName,
        // success && 'dapp-btn-success',
        props.ghost && 'dapp-btn-background-ghost',
        className,
        deviceType,
        {
          'dapp-btn-sm': size === 'small',
        },
        props.loading && 'ant-btn-loading',
      )}
      {...props}
      onClick={(e) => {
        if (props.loading) return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.onClick && props.onClick(e as any);
      }}
      icon={icon}
    />
  );
};

export default Button;
