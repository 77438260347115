import { ErrorDescription } from '@ethersproject/abi/lib.esm/interface';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { ReactNode } from 'react';

export interface IRevertReason {
  reason: string;
  type: 'string' | 'html';
  reasonHtml?: ReactNode;
}

// export interface ITransactionError extends TransactionError {
export interface ITransactionError extends Error {
  /**
   * reset receipt
   */
  receipt?: TransactionReceipt;
  /**
   * revert reason
   */
  revertReason?: IRevertReason;
  /**
   * error code for metamask
   */
  code?: number;
}

/**
 * metamask rpc error
 */
export interface IProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function instanceOfProviderRpcError(object: any): object is IProviderRpcError {
  return 'code' in object;
}

export interface IParsedError extends Error {
  parsedError?: ErrorDescription;
}
