import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { FETCHING_STATUS } from '@/constants';
import { ITokenBalanceInfoMap } from '@/types/balance';

import { AppState } from '../store';
import { getTokenBalance } from './actions';

export interface IBalanceState {
  chainBalanceMap: {
    [chainId: number]: {
      [token: string]: number;
    };
  };
  chainBalanceStatus: {
    [chainId: number]: {
      [token: string]: FETCHING_STATUS;
    };
  };
}

const initialState: IBalanceState = {
  chainBalanceMap: {},
  chainBalanceStatus: {},
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTokenBalance.fulfilled, (state, action) => {
      const { arg } = action.meta;
      if (action.payload) {
        const current = _.get(state.chainBalanceMap, [arg.chainId]);
        _.set(state.chainBalanceMap, [arg.chainId], _.merge(current, action.payload));
        const keys = _.keys(action.payload);
        keys.forEach((key) => {
          _.set(state.chainBalanceStatus, [arg.chainId, key], FETCHING_STATUS.DONE);
        });
      }
    });
  },
});

export const { resetState } = balanceSlice.actions;
export { getTokenBalance as resetChainDefaultBalanceMapAction };

export const selectBalanceState = (state: AppState): IBalanceState => state.balance;
export const selectBalanceMapState =
  (chainId: number | undefined) =>
  (state: AppState): ITokenBalanceInfoMap | undefined =>
    _.get(state.balance.chainBalanceMap, [chainId || '']);
export const selectTokenBalance =
  (chainId: number | undefined, token: string | undefined) =>
  (state: AppState): number =>
    _.get(state.balance.chainBalanceMap, [chainId || '', token || ''], 0);
export const selectTokenBalanceStatus =
  (chainId: number | undefined, token: string | undefined) =>
  (state: AppState): number =>
    _.get(state.balance.chainBalanceStatus, [chainId || '', token || ''], FETCHING_STATUS.INIT);

export default balanceSlice.reducer;
