import { CHAIN_ID } from '@derivation-tech/web3-core';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeCurrentChainId = createAsyncThunk(
  'chain/changeCurrentChainId',

  ({ chainId }: { chainId: CHAIN_ID }): { chainId: number } => {
    return { chainId };
  },
);
