/**
 * @description Component-LoadingWrap
 */
import './index.less';

import React, { FC } from 'react';

import loader from './assets/loader-s.png';
interface IPropTypes {
  className?: string;
  children: React.ReactNode;
  loading?: boolean;
}
const LoadingWrap: FC<IPropTypes> = function ({ loading, children }) {
  return (
    <div className="dapp-loading-wrap">
      {loading ? (
        <span className="dapp-loading-wrap-loading-icon ">
          <img src={loader} />
        </span>
      ) : (
        children
      )}
    </div>
  );
};

export default LoadingWrap;
