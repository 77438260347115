import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { FETCHING_STATUS } from '@/constants';
import { getDefaultListArrayStatus, ListArrayStatus, setFulfilledListArrayStatus } from '@/types/redux';
import { ITxEventTransformed } from '@/types/tx';

import { AppState } from '../store';
import { addTransaction, getTxHistory } from './actions';

export interface IGraphState {
  txHistory: {
    [chainId: number]: ListArrayStatus<ITxEventTransformed>;
  };
}
const initialState: IGraphState = {
  txHistory: {},
};

export const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    console.log('🚀 ~ file: slice.ts:20 ~ builder:', builder);
    builder
      .addCase(getTxHistory.pending, (state, { meta }) => {
        const current = _.get(state.txHistory, [meta.arg.chainId]);
        if (current) {
          _.set(state.txHistory, [meta.arg.chainId, 'status'], FETCHING_STATUS.FETCHING);
        } else {
          _.set(state.txHistory, [meta.arg.chainId], getDefaultListArrayStatus());
        }
      })
      .addCase(getTxHistory.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const curList = _.get(state.txHistory, [meta.arg.chainId, 'list'], []);
          _.set(
            state.txHistory,
            [meta.arg.chainId],
            setFulfilledListArrayStatus(_.uniqBy([...payload, ...curList], (item) => item.txHash + item.logIndex)),
          );
        }
      })
      .addCase(addTransaction, (state, { payload }) => {
        if (payload) {
          const curList = _.get(state.txHistory, [payload.chainId, 'list'], []);
          _.set(state.txHistory, [payload.chainId, 'list'], [payload, ...curList]);
        }
      });
  },
});

export const selectGraphState = (state: AppState): IGraphState => state.graph;
export const selectTxHistory =
  (chainId: number | undefined) =>
  (state: AppState): ITxEventTransformed[] =>
    _.get(state.graph.txHistory, [chainId || '', 'list'], []);
export const selectTxHistoryStatus =
  (chainId: number | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.graph.txHistory, [chainId || '', 'status'], FETCHING_STATUS.INIT);

export default graphSlice.reducer;
