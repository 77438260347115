import _ from 'lodash';
import { useMemo } from 'react';

import { FETCHING_STATUS } from '@/constants';
import { TOKENS } from '@/constants/token';
import { useAppSelector } from '@/hooks';
import { ITxEventTransformed } from '@/types/tx';

import { selectTxHistory, selectTxHistoryStatus } from './slice';

export function useTxHistory(chainId: number | undefined): ITxEventTransformed[] {
  const txs = useAppSelector(selectTxHistory(chainId));
  return useMemo(() => _.orderBy(txs, ['timestamp'], ['desc']), [txs]);
}
export function useTokenTxHistory(chainId: number | undefined, token: TOKENS): ITxEventTransformed[] {
  const txs = useAppSelector(selectTxHistory(chainId));
  return useMemo(
    () =>
      _.orderBy(
        txs.filter((t) => t.symbol === token),
        ['timestamp'],
        ['desc'],
      ),
    [txs, token],
  );
}

export function useTxHistoryStatus(chainId: number | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTxHistoryStatus(chainId));
  return useMemo(() => status, [status]);
}
