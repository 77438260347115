/**
 * @description Component-Landing
 */
import './index.less';

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';

import Login from '../Login';
import { ReactComponent as IconArrow } from './assets/icon_arrow_right.svg';
import line_bg from './assets/line_bg.jpg';
import bgVideo from './assets/line_bg.mp4';
import threeCube from './assets/three_js_cubes.png';
import CubicAnimate from './CubicAnimate';
import Footer from './Footer';
import Header from './Header';
interface IPropTypes {
  className?: string;
}
const Landing: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const [loginOpen, setLoginOpen] = useState(false);
  const [webglOk, setWebglOk] = useState(true);
  return (
    <div className="dapp-landing">
      <video preload="metadata" poster={line_bg} className="dapp-landing-bg" loop muted autoPlay>
        <source type="video/mp4" src={bgVideo} />
      </video>
      <div className="dapp-landing-content">
        <Header openLogin={() => setLoginOpen(true)} />
        <div className="dapp-landing-content-middle">
          <div className="dapp-landing-content-middle-left">
            <div className="dapp-landing-content-middle-left-header">{t('common.landing.header')}</div>
            <div className="dapp-landing-content-middle-left-desc">
              <div>{t('common.landing.desc')}</div>
              <div>{t('common.landing.desc2')}</div>
              <div>{t('common.landing.desc3')}</div>
            </div>
            <Button type="ghost" onClick={() => setLoginOpen(true)} className="dapp-landing-content-middle-left-btn">
              {t('common.landing.btnTxt')} <IconArrow />
            </Button>
          </div>
          <CubicAnimate weglChange={setWebglOk} className="dapp-landing-content-middle-right" />
          {!webglOk && <img className="dapp-landing-content-middle-right" src={threeCube} />}
        </div>
        <Footer />
      </div>
      <Login open={loginOpen} onClose={() => setLoginOpen(false)} />
    </div>
  );
};

export default Landing;
