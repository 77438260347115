/**
 * @description Component-SendToWallet
 */
import './index.less';

import { Form } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { ReactComponent as ErrorIcon } from '@/assets/svg/icon_error.svg';
import { ReactComponent as ReviewIcon } from '@/assets/svg/icon_review_12.svg';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo_color_dark.svg';
import { Button } from '@/components/Button';
import Modal from '@/components/Modal';
import { FETCHING_STATUS } from '@/constants';
import { ADDRESS_INFO, SEND_PAGES } from '@/constants/global';
import { WrappedBigNumber } from '@/entities/WrappedBigNumber';
import { useTokenBalanceByChainIdAndToken } from '@/features/balance/hook';
import { sendToWallet, setUserTxStatus } from '@/features/user/actions';
import { useUserTxStatus, useUserWalletInfo } from '@/features/user/hooks';
import { useContract, useCurrentToken } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import AddressInfo from '@/pages/components/AddressInfo';
import AmountInput from '@/pages/components/AmountInput';
import WalletRecipientSelector from '@/pages/components/WalletRecipientSelector';
import { isAddress } from '@/utils/address';

import SendSuccess from '../SendToBank/SendSuccess';
interface IPropTypes {
  className?: string;
}
const SendToWallet: FC<IPropTypes> = function () {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState(ADDRESS_INFO.address);
  const dispatch = useAppDispatch();
  const currentToken = useCurrentToken();
  const walletInfo = useUserWalletInfo();
  const contract = useContract(currentToken);
  const chainId = useChainId();
  const balance = useTokenBalanceByChainIdAndToken(currentToken, chainId);
  const insufficient = useMemo(() => (amount ? WrappedBigNumber.from(balance).lt(amount) : false), [balance, amount]);
  const txStatus = useUserTxStatus();
  const send = useCallback(async () => {
    if (chainId && contract && amount && walletInfo && address && isAddress(address)) {
      await dispatch(
        sendToWallet({
          chainId,
          contractAddr: contract.address,
          to: address,
          username: walletInfo.username,
          amount: Number(amount),
        }),
      ).unwrap();
      setAmount('');
    }
  }, [amount, address, chainId, contract, dispatch, walletInfo]);
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          dispatch(setUserTxStatus(FETCHING_STATUS.INIT));
          setAmount('');
          setAddress(ADDRESS_INFO.address);
        }}
        className="dapp-btn-sm"
        type="ghost">
        {' '}
        {t('common.detail.wallet')}
      </Button>
      <Modal
        open={open}
        title={false}
        footer={false}
        closable={false}
        closeIcon={false}
        maskClosable={false}
        width={window.innerWidth}
        onCancel={() => setOpen(false)}
        onOk={() => txStatus !== FETCHING_STATUS.FETCHING && setOpen(false)}
        className="dapp-send-to-wallet full">
        <Form initialValues={{ address }}>
          <div className="dapp-send-to-wallet-header">
            <LogoIcon />
            <span className="title">{t('common.sendPage.walletTitle')}</span>

            <Button type="text" icon={<CloseIcon onClick={() => setOpen(false)} />} />
          </div>
          <div className="dapp-send-to-wallet-content">
            {txStatus === FETCHING_STATUS.DONE ? (
              <SendSuccess onConfirm={() => setOpen(false)} />
            ) : (
              <>
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="dapp-send-to-wallet-content-error">
                          <ErrorIcon />
                          {t('common.sendPage.amountRequired')}
                        </div>
                      ),
                    },
                    {
                      message: (
                        <div className="dapp-send-to-wallet-content-error">
                          <ErrorIcon /> {t('common.sendPage.insufficientBal')}
                        </div>
                      ),
                      validator: async () => (insufficient ? Promise.reject() : Promise.resolve()),
                    },
                  ]}>
                  <AmountInput balance={balance} onChange={setAmount} type={SEND_PAGES.WALLET} />
                </Form.Item>
                <div className="dapp-send-to-wallet-content-recipient">
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: (
                          <div className="dapp-send-to-wallet-content-error">
                            <ErrorIcon />
                            {t('common.sendPage.addressRequired')}
                          </div>
                        ),
                      },
                      {
                        message: (
                          <div className="dapp-send-to-wallet-content-error">
                            <ErrorIcon /> {t('common.sendPage.addressError')}
                          </div>
                        ),
                        validator: async () => (isAddress(address) || !address ? Promise.resolve() : Promise.reject()),
                      },
                    ]}>
                    <WalletRecipientSelector onChange={setAddress} />
                  </Form.Item>
                  <div className="dapp-send-to-wallet-content-detail">
                    <div className="dapp-send-to-wallet-content-detail-title">
                      <ReviewIcon />
                      {t('common.recSelector.walletDetail')}
                    </div>
                    <AddressInfo chainId={chainId} accountName="" address={address} />
                  </div>
                </div>
                <Button
                  htmlType="submit"
                  disabled={txStatus === FETCHING_STATUS.FETCHING}
                  loading={txStatus === FETCHING_STATUS.FETCHING}
                  onClick={send}
                  type="primary">
                  {txStatus === FETCHING_STATUS.FETCHING ? t('common.sending') : t('common.send')}
                </Button>
                {/* {txStatus !== FETCHING_STATUS.INIT && (
                <div className="dapp-send-to-wallet-content-fetching">
                  {txStatus === FETCHING_STATUS.FETCHING && (
                    <>
                      <div className="dapp-send-to-wallet-content-fetching-title">
                        <OkIcon />
                        {t('common.sendPage.txSent')}
                      </div>
                      <Button type="text" loading={true} className="dapp-send-to-wallet-content-fetching-btn">
                        {t('common.sendPage.waitConfirm')}
                      </Button>
                    </>
                  )}
                  {txStatus === FETCHING_STATUS.DONE && (
                    <div className="dapp-send-to-wallet-content-fetching-title">
                      {' '}
                      <OkIcon />
                      {t('common.done')}
                    </div>
                  )}
                </div>
              )} */}
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SendToWallet;
