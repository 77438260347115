const prefix = 'dapp';

/**
 * localStorage start
 */
export const LOCAL_THEME = `${prefix}-theme`;
export const ALL_USER_INFO = `${prefix}-all-user-info`;
export const GLOBAL_CONFIG = `global-config`;
export const CREATED_WALLET = `CREATED_WALLET`;

/**
 * localStorage end
 */

/**
 * localForage start
 */
// the localforage sometimes needs to be clean for newer releases
// to achieve this simply bump up the keys down

export const APP_CHAIN_ID = 'APP_CHAIN_ID';

/**
 * localForage end
 */
