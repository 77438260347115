import React from 'react';

import { useChainId } from '@/hooks/web3/useChain';
import { useUserWorkerEventListener } from '@/worker/hooks/useWorkerHooks';

export function UserEventListener(): JSX.Element {
  const chainId = useChainId();
  useUserWorkerEventListener(chainId);
  return <></>;
}
