import React, { useEffect } from 'react';

import { TOKENS } from '@/constants/token';
import { getTxHistory } from '@/features/graph/actions';
import { useUserWalletInfo } from '@/features/user/hooks';
import { useContract, useSDKContext } from '@/features/web3/hook';
import { useAppDispatch } from '@/hooks';
import { useChainId } from '@/hooks/web3/useChain';
import { pollingFunc } from '@/utils';

import { getTokenBalance } from '../actions';
export function useInitBalance(): void {
  const HKDContract = useContract(TOKENS.DHKD);
  const USDContract = useContract(TOKENS.DUSD);
  const walletInfo = useUserWalletInfo();
  const chainId = useChainId();
  const sdkContext = useSDKContext(chainId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    let polling: NodeJS.Timeout;
    if (chainId && walletInfo && sdkContext) {
      polling = pollingFunc(() => {
        dispatch(
          getTxHistory({
            chainId,
            userAddr: walletInfo.address,
            sdkContext,
          }),
        );
        if (HKDContract) {
          dispatch(
            getTokenBalance({
              chainId,
              userAddress: walletInfo.address,
              contract: HKDContract,
            }),
          );
        }
        if (USDContract) {
          dispatch(
            getTokenBalance({
              chainId,
              userAddress: walletInfo.address,
              contract: USDContract,
            }),
          );
        }
      }, 30000);
    }
    return () => {
      polling && clearInterval(polling);
    };
  }, [HKDContract, USDContract, chainId, dispatch, sdkContext, walletInfo]);
}
function BalanceGlobalEffect(): JSX.Element {
  useInitBalance();
  return <></>;
}

export default React.memo(BalanceGlobalEffect);
