import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { useCallback, useMemo } from 'react';

import { WEB3_NETWORK_TYPE } from '@/constants';
import { CHAIN_ID, chainConfigs, SUPPORTED_CHAIN_ID } from '@/constants/chain';
import { selectCurrentId } from '@/features/chain/chainSlice';
import { useCurrentId } from '@/features/chain/hook';
import { useUserAddrState } from '@/features/user/hooks';
import { useWalletProvider } from '@/hooks/web3/useWalletNetwork';
import { useAppProvider } from '@/hooks/web3/useWeb3Network';
import { getChainName } from '@/utils/chain';
import { getProviderOrSigner, getSignerFromProvider } from '@/utils/signer';

import { useAppSelector } from '..';

export function useChainId(): CHAIN_ID | undefined {
  const chainId = useAppSelector(selectCurrentId);
  return chainId;
}

export function useChainName(chainId?: CHAIN_ID): string | undefined {
  const defaultChainId = useChainId();

  if (!chainId) chainId = defaultChainId;

  return useMemo(() => {
    return chainId ? getChainName(chainId) : undefined;
  }, [chainId]);
}

export const useIsSupportedChain = (): boolean => {
  const chainId = useCurrentId();

  const getIsChainAvailable = useCallback((chainId: number) => {
    return SUPPORTED_CHAIN_ID.includes(chainId);
  }, []);

  const isChainAvailable = useMemo(() => {
    if (!chainId) return false;
    return getIsChainAvailable(chainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return isChainAvailable;
};

export const useEtherscanLink = (): ((data: string, type: 'transaction' | 'token' | 'address' | 'block') => string) => {
  const chainId = useChainId();
  const getEtherscanLink = useCallback(
    (data: string, type: 'transaction' | 'token' | 'address' | 'block'): string => {
      const chainConfigInfo = chainConfigs[chainId as number];
      const prefix = chainConfigInfo.explorer;

      switch (type) {
        case 'transaction': {
          return `${prefix}/tx/${data}`;
        }
        case 'token': {
          return `${prefix}/token/${data}`;
        }
        case 'block': {
          return `${prefix}/block/${data}`;
        }
        case 'address':
        default: {
          return `${prefix}/address/${data}`;
        }
      }
    },
    [chainId],
  );

  return getEtherscanLink;
};

/**
 * get app or wallet connector's provider
 * @param web3NetworkType
 * @returns
 */
export function useProvider(web3NetworkType?: WEB3_NETWORK_TYPE): JsonRpcProvider | undefined {
  const walletProvider = useWalletProvider();
  const appProvider = useAppProvider();
  return useMemo(() => {
    return web3NetworkType === WEB3_NETWORK_TYPE.Wallet ? walletProvider : appProvider;
  }, [walletProvider, appProvider, web3NetworkType]);
}
export function useUserAddr(): string | undefined {
  return useUserAddrState();
}

export const useSigner = (web3NetworkType?: WEB3_NETWORK_TYPE): JsonRpcSigner | undefined => {
  const provider = useProvider(web3NetworkType);
  const account = useUserAddr();
  return useMemo(() => {
    if (provider && account) {
      return getSignerFromProvider(provider, account);
    }
    return undefined;
  }, [account, provider]);
};

export const useProviderOrSigner = (
  provider: JsonRpcProvider | undefined,
  account: string | null | undefined,
): JsonRpcProvider | JsonRpcSigner | undefined => {
  return useMemo(() => {
    if (provider) {
      return getProviderOrSigner(provider, account ?? undefined);
    }
    return undefined;
  }, [account, provider]);
};
