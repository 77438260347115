import iconMetamask from '@/assets/images/wallets/Metamask.svg';
import iconWalletConnect from '@/assets/images/wallets/wallet-connect.svg';
import { metaMaskConnector, metaMaskConnectorHooks } from '@/connectors/metaMask';
import { walletConnectConnector, walletConnectHooks } from '@/connectors/walletConnectV2';
import { IWalletInfo } from '@/types/wallet';

export const enum WalletType {
  METAMASK = 'MetaMask',
  WALLET_CONNECT = 'WalletConnect',
}

export enum WALLET_CONNECT_STATUS {
  UN_CONNECT = 'UnConnect',
  WRONG_NETWORK = 'WrongNetwork',
  CONNECTED = 'Connected',
}

export const SUPPORTED_WALLETS: { [key: string]: IWalletInfo } = {
  [WalletType.METAMASK]: {
    connector: metaMaskConnector,
    hooks: metaMaskConnectorHooks,
    name: WalletType.METAMASK,
    type: WalletType.METAMASK,
    iconName: iconMetamask,
    mobile: true,
    mobileDeepLink: `https://metamask.app.link/dapp/`,
  },

  [WalletType.WALLET_CONNECT]: {
    connector: walletConnectConnector,
    hooks: walletConnectHooks,
    name: WalletType.WALLET_CONNECT,
    type: WalletType.WALLET_CONNECT,
    iconName: iconWalletConnect,
    mobile: true,
  },
};
