import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';

import { CHAIN_ID, SUPPORTED_CHAIN_ID } from '@/constants/chain';

export const [walletConnectConnector, walletConnectHooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '',
        chains: [CHAIN_ID.GOERLI],
        optionalChains: SUPPORTED_CHAIN_ID,
        showQrModal: true,
        qrModalOptions: {
          themeVariables: {
            '--w3m-z-index': '9999',
          },
        },
      },
      defaultChainId: CHAIN_ID.GOERLI,
    }),
);
