import { URI_AVAILABLE } from '@web3-react/walletconnect-v2';
import { useAsyncEffect } from 'ahooks';
import _ from 'lodash';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { WalletType } from '@/constants/wallets';
import { useWalletType } from '@/features/wallet/hook';
import { setWalletType } from '@/features/wallet/walletSlice';
import { useAppDispatch } from '@/hooks';

import { appConnector } from './appConnector';
import { metaMaskConnector } from './metaMask';
import { walletConnectConnector } from './walletConnectV2';

function useActiveAppConnector() {
  useEffect(() => {
    void appConnector.activate().catch(() => {
      console.warn('Failed to connect to network');
    });
  }, []);
}

function useActiveMetamaskConnector() {
  const walletType = useWalletType();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMobile && window.ethereum) {
      metaMaskConnector.activate();
    } else {
      void metaMaskConnector
        .connectEagerly()
        .then(() => {
          console.record('wallet', 'connected eagerly to [metamask]');
          if (walletType === WalletType.METAMASK && _.get(window.ethereum, ['isCoin98'])) {
            dispatch(setWalletType({ type: WalletType.WALLET_CONNECT }));
          }
        })
        .catch(() => {
          console.warn('Failed to connect eagerly to metamask');
        });
    }
  }, [dispatch, walletType]);
}

function useActiveWalletConnectConnector() {
  useEffect(() => {
    walletConnectConnector.events.on(URI_AVAILABLE, (uri: string) => {
      console.info(`walletConnectConnector uri: ${uri}`);
    });
  }, []);
  useAsyncEffect(async () => {
    // ignore test mode
    if (process.env.NODE_ENV !== 'test') {
      try {
        // TODO: temp disable wallet connect
        // await walletConnectConnector.connectEagerly();
      } catch (error) {
        console.error('Failed to connect eagerly to walletconnect');
      }
    }
  }, []);
}

export default function ActiveConnectorManager({ children }: { children: JSX.Element }): JSX.Element {
  useActiveAppConnector();
  useActiveMetamaskConnector();
  useActiveWalletConnectConnector();

  return children;
}
