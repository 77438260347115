import { CHAIN_ID } from '@derivation-tech/web3-core';

import HKDIcon from '@/assets/svg/icon_coin_hkds.svg';
import USDIcon from '@/assets/svg/icon_coin_usds.svg';
/**
 * the min gas price limit for native token to keep in the wallet
 */
export const NATIVE_TOKEN_MIN_GAS_PRICE_LIMIT: { [chainId: number]: number } = {
  [CHAIN_ID.GOERLI]: 0.2,
  [CHAIN_ID.ARBITRUM]: 0.2,
};

export enum TOKENS {
  DHKD = 'DHKD',
  DUSD = 'DUSD',
}
export const TOKENS_CURRENCY = {
  [TOKENS.DHKD]: 'HKD',
  [TOKENS.DUSD]: 'USD',
};
export const TOKENS_FULL_NAME = {
  [TOKENS.DHKD]: 'Digital HKD',
  [TOKENS.DUSD]: 'Digital USD',
};

export const TOKENS_ICON_MAP = {
  [TOKENS.DHKD]: HKDIcon,
  [TOKENS.DUSD]: USDIcon,
};
