import { BigNumber } from 'ethers';

import { WrappedBigNumber } from '@/entities/WrappedBigNumber';

/**
 * default display decimals places
 */
export const DEFAULT_DECIMAL_PLACES = 2;

export const ETH_DECIMALS_PLACES = 18;

/**
 * fetching status
 */
export enum FETCHING_STATUS {
  INIT = 0,
  FETCHING = 1, // >0 display loading
  DONE = -1,
}

// theme key
export enum THEME_ENUM {
  DARK = 'dark-theme',
  LIGHT = 'light-theme',
}
export type SystemPrefersColorScheme = 'auto' | THEME_ENUM;

export enum WEB3_NETWORK_TYPE {
  Dapp = 'Dapp',
  Wallet = 'Wallet',
}

export const WAD = BigNumber.from(String(10 ** 18));
export const ZERO = BigNumber.from(0);
export const WRAPPED_ZERO = WrappedBigNumber.from(0);
export const ONE = BigNumber.from(1);
export const TWO = BigNumber.from(2);
