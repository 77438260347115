/**
 * @description Component-TokenCard
 */
import './index.less';

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingWrap from '@/components/LoadingWrap';
import { FETCHING_STATUS } from '@/constants';
import { TOKENS, TOKENS_FULL_NAME, TOKENS_ICON_MAP } from '@/constants/token';
import { useTokenBalanceByChainIdAndToken, useTokenBalanceStatus } from '@/features/balance/hook';
import { useChainId } from '@/hooks/web3/useChain';
import { formatNumber } from '@/utils/numberUtil';
interface IPropTypes {
  className?: string;
  token: TOKENS;
}
const TokenCard: FC<IPropTypes> = function ({ token }) {
  const navigate = useNavigate();
  const chainId = useChainId();
  const balance = useTokenBalanceByChainIdAndToken(token, chainId);
  const balanceStatus = useTokenBalanceStatus(token, chainId);
  return (
    <div onClick={() => navigate(`/home/token/${token}`)} className="dapp-token-card">
      <img className="dapp-token-icon" src={TOKENS_ICON_MAP[token]} />
      <div className="dapp-token-card-amount">
        <LoadingWrap loading={balanceStatus !== FETCHING_STATUS.DONE}>{formatNumber(balance, 2)} </LoadingWrap>
      </div>
      <div className="dapp-token-card-symbol">{TOKENS_FULL_NAME[token]}</div>
    </div>
  );
};

export default TokenCard;
